// FETable.js

import { useContext, useState, useEffect, useMemo } from 'react';
import {
  Button,
  Input,
  Layout,
  Table,
  message,
  Spin,
  Modal,
  Space,
} from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import styled from 'styled-components/macro';
import axios from 'axios';
import { authContext } from '../ProvideAuth.js';
import moment from 'moment';
import { convertToIntDateFormat } from '../utils.js';
import { DateFilterDropdown } from './DateFilterDropdown.js';
import { get_moments_from_month_name } from '../utils.js';
import AccountingAccountSelect from './Accounting/AccountingAccountSelect';
import * as XLSX from 'xlsx'; // Import XLSX for CSV export
import FEbuttonAndModal from './FEbuttonAndModal'; // Import FEbuttonAndModal component

const { Content } = Layout;

function FETable(props) {
  const [fes, setFes] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // State for filtered data
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedFEs, setSelectedFEs] = useState([]);
  const [isCommitModalVisible, setIsCommitModalVisible] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const auth = useContext(authContext);

  const [currentFilters, setCurrentFilters] = useState({
    document_type: null,
    emission_date: null,
    cufe: null,
    emissor: null, // Added emissor to filters
    receiver: null, // Added receiver to filters
  });

  useEffect(() => {
    const fetchFEs = () => {
      setLoading(true);

      axios({
        method: 'post',
        url: props.API_domain + 'getFEs',
        auth: {
          username: auth.email,
          password: auth.token,
        },
        data: {
          docs_type: props.type,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            setFes(response.data);
            setFilteredData(response.data); // Initialize filtered data
            console.log('Fetched fes:', response.data);
          } else {
            message.error('Failed to fetch fes');
          }
        })
        .catch((error) => {
          console.error('Error fetching fes:', error);
          message.error('Error consiguiendo las facturas electrónicas');
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchFEs();
  }, [props.API_domain, props.type, auth.email, auth.token]);

  // Function to handle account change
  const handleAccountChange = (record, value) => {
    // Call the backend to update the record
    axios({
      method: 'post',
      url: props.API_domain + 'updateFEAccount',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        doc_type: props.type, // record.type
        doc_id: record.id, // record id
        account_id: value, // new account_id
      },
    })
      .then((response) => {
        if (response.status === 200) {
          message.success('Cuenta actualizada correctamente');
          // Update the fes state to reflect the change
          setFes((prevFes) =>
            prevFes.map((item) =>
              item.id === record.id ? { ...item, account_id: value } : item
            )
          );
        } else {
          message.error('Error al actualizar la cuenta');
        }
      })
      .catch((error) => {
        console.error('Error updating account:', error);
        message.error('Error al actualizar la cuenta');
      });
  };

  // Compute unique document_type values for filters
  const documentTypeFilters = useMemo(() => {
    const documentTypesSet = new Set();
    fes.forEach((fe) => {
      if (fe.document_type) {
        documentTypesSet.add(fe.document_type);
      }
    });
    const filters = Array.from(documentTypesSet).map((id) => ({
      text: id.toString(),
      value: id.toString(),
    }));
    return filters;
  }, [fes]);

  const columns = [
    {
      title: 'CUFE',
      dataIndex: 'cufe',
      key: 'cufe',
      width: 240,
      render: (text, record) => (
        <FEbuttonAndModal
          numero_factura={text}
          API_domain={props.API_domain}
          auth={auth}
        />
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Buscar CUFE`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type='primary'
              onClick={confirm}
              icon={<SearchOutlined />}
              size='small'
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button onClick={clearFilters} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) => {
        const searchText = value.toLowerCase();
        return record.cufe.toLowerCase().includes(searchText);
      },
      filteredValue: currentFilters.cufe || null,
    },
    ...(props.type === 'fe_recibidas'
      ? [
          {
            title: 'Emisor',
            key: 'emissor',
            width: 400,
            render: (text, record) => (
              <div>
                <div>{record.emissor_name}</div>
                <div style={{ fontSize: '12px', color: '#888' }}>
                  {record.emissor_id}
                </div>
              </div>
            ),
            filterIcon: (filtered) => (
              <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
              />
            ),
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div style={{ padding: 8 }}>
                <Input
                  placeholder={`Buscar emisor o RUC`}
                  value={selectedKeys[0]}
                  onChange={(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  onPressEnter={confirm}
                  style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                  <Button
                    type='primary'
                    onClick={confirm}
                    icon={<SearchOutlined />}
                    size='small'
                    style={{ width: 90 }}
                  >
                    Buscar
                  </Button>
                  <Button
                    onClick={clearFilters}
                    size='small'
                    style={{ width: 90 }}
                  >
                    Reset
                  </Button>
                </Space>
              </div>
            ),
            onFilter: (value, record) => {
              const searchText = value.toLowerCase();
              const emissorName = record.emissor_name
                ? record.emissor_name.toLowerCase()
                : '';
              const emissorId = record.emissor_id
                ? record.emissor_id.toString()
                : '';
              return (
                emissorName.includes(searchText) ||
                emissorId.includes(searchText)
              );
            },
            filteredValue: currentFilters.emissor || null,
          },
        ]
      : []),
    ...(props.type === 'fe_emitidas'
      ? [
          {
            title: 'Receptor',
            key: 'receiver',
            width: 360,
            render: (text, record) => (
              <div>
                <div>{record.receiver_name}</div>
                <div style={{ fontSize: '12px', color: '#888' }}>
                  {record.receiver_id !== 'missing' ? record.receiver_id : ''}
                </div>
              </div>
            ),
            filterIcon: (filtered) => (
              <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
              />
            ),
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div style={{ padding: 8 }}>
                <Input
                  placeholder={`Buscar receptor o RUC`}
                  value={selectedKeys[0]}
                  onChange={(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  onPressEnter={confirm}
                  style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                  <Button
                    type='primary'
                    onClick={confirm}
                    icon={<SearchOutlined />}
                    size='small'
                    style={{ width: 90 }}
                  >
                    Buscar
                  </Button>
                  <Button
                    onClick={clearFilters}
                    size='small'
                    style={{ width: 90 }}
                  >
                    Reset
                  </Button>
                </Space>
              </div>
            ),
            onFilter: (value, record) => {
              const searchText = value.toLowerCase();
              const receiverName = record.receiver_name
                ? record.receiver_name.toLowerCase()
                : '';
              const receiverId = record.receiver_id
                ? record.receiver_id.toString()
                : '';
              return (
                receiverName.includes(searchText) ||
                receiverId.includes(searchText)
              );
            },
            filteredValue: currentFilters.receiver || null,
          },
        ]
      : []),
    {
      title: 'Fecha de Emisión',
      dataIndex: 'emission_date',
      key: 'emission_date',
      width: 240,
      sorter: (a, b) =>
        moment(a.emission_date).unix() - moment(b.emission_date).unix(),
      render: (text) =>
        convertToIntDateFormat(moment(text).format('YYYY-MM-DD')),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <DateFilterDropdown
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        const emissionDate = moment(record.emission_date);

        if (Array.isArray(value) && value.length === 2) {
          const [start, end] = value;
          return emissionDate.isBetween(start, end, 'day', '[]');
        } else if (typeof value === 'string') {
          const filterDates = get_moments_from_month_name(value);
          return emissionDate.isBetween(
            filterDates[0],
            filterDates[1],
            'day',
            '[]'
          );
        }

        return false;
      },
      filteredValue: currentFilters.emission_date || null, // Bind filteredValue
    },
    ...(auth.adminEmail
      ? [
          {
            title: 'Tipo de documento 🦸‍♂️',
            dataIndex: 'document_type',
            key: 'document_type',
            width: 160,
            filters: documentTypeFilters,
            onFilter: (value, record) =>
              record.document_type.toString() === value,
            render: (text) => text,
            filteredValue: currentFilters.document_type || null,
          },
        ]
      : []),
    {
      title: 'ITBMS',
      dataIndex: 'itbms',
      key: 'itbms',
      render: (value) =>
        value !== undefined ? `$${value.toFixed(2)}` : '$0.00',
      sorter: (a, b) => (a.itbms || 0) - (b.itbms || 0),
      align: 'right',
    },
    {
      title: 'Total',
      dataIndex: 'amount',
      key: 'amount',
      render: (value) => (
        <div style={{ textAlign: 'right' }}>
          {value !== undefined ? `$${value.toFixed(2)}` : '$0.00'}
        </div>
      ),
      sorter: (a, b) => (a.amount || 0) - (b.amount || 0),
      align: 'right',
    },
    ...(auth.adminEmail
      ? [
          {
            title: 'Cuenta 🦸‍♂️',
            dataIndex: 'account_id',
            key: 'account_id',
            width: 400,
            render: (text, record) => (
              <AccountingAccountSelect
                value={record.account_id}
                onChange={(value) => handleAccountChange(record, value)}
                clientId={props.clientId}
                API_domain={props.API_domain}
                auth={auth}
                accounts={props.clientAccounts}
              />
            ),
          },
        ]
      : []),
  ];

  // Function to export table data to CSV
  const exportToCSV = () => {
    if (!filteredData || filteredData.length === 0) {
      message.warning('No hay datos para exportar.');
      return;
    }

    // Define headers based on columns
    const headers = ['CUFE'];

    if (props.type === 'fe_recibidas') {
      headers.push('Emisor Nombre', 'Emisor RUC');
    } else if (props.type === 'fe_emitidas') {
      headers.push('Receptor Nombre', 'Receptor RUC');
    }

    headers.push('Fecha de Emisión', 'Tipo de documento', 'ITBMS', 'Total');

    // Map data to rows
    const data = filteredData.map((item) => {
      const row = [item.cufe];

      if (props.type === 'fe_recibidas') {
        row.push(item.emissor_name, item.emissor_id);
      } else if (props.type === 'fe_emitidas') {
        row.push(
          item.receiver_name,
          item.receiver_id !== 'missing' ? item.receiver_id : ''
        );
      }

      row.push(moment(item.emission_date).format('YYYY-MM-DD'));
      row.push(item.document_type);
      row.push(item.itbms !== undefined ? `${item.itbms.toFixed(2)}` : '0.00');
      row.push(
        item.amount !== undefined ? `${item.amount.toFixed(2)}` : '0.00'
      );

      return row;
    });

    // Combine headers and data
    const csvData = [headers, ...data];

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(csvData);

    // Create a workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'FEs');

    // Generate a binary string representation of the workbook
    const wbout = XLSX.write(wb, { bookType: 'csv', type: 'array' });

    // Create a Blob from the binary string
    const blob = new Blob([wbout], { type: 'text/csv;charset=utf-8;' });

    // Create a link to download the Blob
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'FEs.csv';
    a.click();
    URL.revokeObjectURL(url);
  };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedFEs(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleCommit = () => {
    if (selectedFEs.length === 0) {
      message.warning('No hay facturas seleccionadas para confirmar.');
      return;
    }
    setIsCommitModalVisible(true);
  };

  const handleCommitConfirm = () => {
    if (!selectedAccount) {
      message.warning('Por favor seleccione una cuenta.');
      return;
    }

    axios({
      method: 'post',
      url: props.API_domain + 'commitFEs',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        fe_ids: selectedFEs.map((fe) => fe.id),
        account_id: selectedAccount,
        doc_type: props.type,
        client_id: props.clientId,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          message.success('Facturas confirmadas correctamente.');
          // Optionally update the fes state or refetch data
          setSelectedRowKeys([]);
          setSelectedFEs([]);
          setIsCommitModalVisible(false);
          setSelectedAccount(null);
        } else {
          message.error('Error al confirmar las facturas.');
        }
      })
      .catch((error) => {
        console.error('Error committing FEs:', error);
        message.error('Error al confirmar las facturas.');
      });
  };

  const onTableChange = (pagination, filters, sorter, extra) => {
    setCurrentFilters(filters);
    if (extra && extra.currentDataSource) {
      setFilteredData(extra.currentDataSource);
    } else {
      setFilteredData(fes);
    }
  };

  return (
    <Content
      style={{
        overflow: 'initial',
        borderTop: 'solid rgb(235,235,235) 1px',
      }}
    >
      <AffixDiv style={{ maxWidth: '1400px', margin: 'auto' }}>
        <div style={{ textAlign: 'right' }}>
          {auth.adminEmail && (
            <Button
              type='primary'
              onClick={handleCommit}
              disabled={selectedFEs.length === 0}
              style={{ marginRight: 8 }}
            >
              Commit 🦸‍♂️
            </Button>
          )}
          <Button
            type='secondary'
            icon={<DownloadOutlined />}
            onClick={exportToCSV}
          >
            Excel
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={fes}
          rowKey='id'
          loading={loading}
          pagination={{ pageSize: 100 }}
          rowSelection={auth.adminEmail ? rowSelection : null}
          style={{ margin: 'auto' }}
          onChange={onTableChange}
        />
      </AffixDiv>
      <Modal
        title='Seleccione una cuenta para confirmar'
        visible={isCommitModalVisible}
        onOk={handleCommitConfirm}
        onCancel={() => {
          setIsCommitModalVisible(false);
          setSelectedAccount(null);
        }}
      >
        <AccountingAccountSelect
          value={selectedAccount}
          onChange={(value) => setSelectedAccount(value)}
          clientId={props.clientId}
          API_domain={props.API_domain}
          auth={auth}
          accounts={props.clientAccounts}
        />
      </Modal>
    </Content>
  );
}

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
`;

export { FETable };
