// AccountingTransactionsTable.js

import React, { useState, useEffect, useContext } from 'react';
import { Table, Modal, Button, message, Popconfirm, Input, Select, DatePicker } from 'antd';
import axios from 'axios';
import { DeleteOutlined } from '@ant-design/icons';
import AccountingAccountSelect from './AccountingAccountSelect'; // Import the component
import { authContext } from '../../ProvideAuth.js';
import moment from 'moment';

function AccountingTransactionsTable({ API_domain, accountingClientCompany }) {
  const auth = useContext(authContext);
  const [transactions, setTransactions] = useState([]);
  const [entries, setEntries] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // State for creating new transaction
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [newEntries, setNewEntries] = useState([]);
  const [newTransactionDescription, setNewTransactionDescription] = useState('');
  const [newTransactionDate, setNewTransactionDate] = useState(moment()); // Default to today
  const [totalDebits, setTotalDebits] = useState(0);
  const [totalCredits, setTotalCredits] = useState(0);

  // Fetch transactions from the backend
  useEffect(() => {
    fetchTransactions();
  }, [API_domain, accountingClientCompany, auth]);

  const fetchTransactions = async () => {
    try {
      const response = await axios.post(
        `${API_domain}getAccountingTransactions`,
        {
          client_id: accountingClientCompany.accounting_client_id,
        },
        {
          auth: {
            username: auth.email,
            password: auth.token,
          },
        }
      );
      setTransactions(response.data.transactions);
    } catch (error) {
      console.error('Error fetching transactions:', error);
      message.error('Error al obtener las transacciones');
    }
  };

  // Fetch entries for a selected transaction
  const fetchEntries = async (transaction_id) => {
    try {
      const response = await axios.post(
        `${API_domain}getTransactionEntries`,
        {
          transaction_id,
        },
        {
          auth: {
            username: auth.email,
            password: auth.token,
          },
        }
      );
      setEntries(response.data.entries);
    } catch (error) {
      console.error('Error fetching entries:', error);
      message.error('Error al obtener las entradas');
    }
  };

  // Handle row click to open modal
  const handleRowClick = (record) => {
    setSelectedTransaction(record);
    fetchEntries(record.transaction_id);
    setIsModalVisible(true);
  };

  // Handle transaction deletion
  const handleDeleteTransaction = async (transaction_id) => {
    try {
      await axios.post(
        `${API_domain}deleteTransaction`,
        {
          transaction_id,
        },
        {
          auth: {
            username: auth.email,
            password: auth.token,
          },
        }
      );
      message.success('Transacción eliminada exitosamente');
      // Refresh transactions list
      setTransactions((prev) =>
        prev.filter((t) => t.transaction_id !== transaction_id)
      );
    } catch (error) {
      console.error('Error deleting transaction:', error);
      message.error('Error al eliminar la transacción');
    }
  };

  // Handle entry account update
  const handleAccountChange = (entry_id, new_account_id) => {
    setEntries((prevEntries) =>
      prevEntries.map((entry) =>
        entry.entry_id === entry_id
          ? { ...entry, account_id: new_account_id }
          : entry
      )
    );
  };

  // Save updated entries
  const handleSaveEntries = async () => {
    try {
      await axios.post(
        `${API_domain}updateEntries`,
        {
          entries,
        },
        {
          auth: {
            username: auth.email,
            password: auth.token,
          },
        }
      );
      message.success('Entradas actualizadas exitosamente');
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error updating entries:', error);
      message.error('Error al actualizar las entradas');
    }
  };

  // Handle adding new entry in create transaction modal
  const addNewEntry = () => {
    const newKey = new Date().getTime(); // Use timestamp as unique key
    setNewEntries([
      ...newEntries,
      {
        key: newKey,
        account_id: null,
        amount: '',
        entry_type: 'D', // default to Debit
      },
    ]);
  };

  // Handle changes in new entries
  const handleNewEntryChange = (key, field, value) => {
    setNewEntries((prevEntries) => {
      const updatedEntries = prevEntries.map((entry) => {
        if (entry.key === key) {
          return { ...entry, [field]: value };
        }
        return entry;
      });
      return updatedEntries;
    });
  };

  // Remove an entry from new entries
  const removeNewEntry = (key) => {
    setNewEntries((prevEntries) => prevEntries.filter((entry) => entry.key !== key));
  };

  // Calculate total debits and credits whenever newEntries change
  useEffect(() => {
    let debits = 0;
    let credits = 0;
    newEntries.forEach((entry) => {
      const amount = parseFloat(entry.amount) || 0;
      if (entry.entry_type === 'D') {
        debits += amount;
      } else {
        credits += amount;
      }
    });
    setTotalDebits(debits);
    setTotalCredits(credits);
  }, [newEntries]);

  // Handle creating a new transaction
  const handleCreateTransaction = async () => {
    try {
      // Prepare data to send
      const transactionData = {
        description: newTransactionDescription,
        transaction_date: newTransactionDate.format('YYYY-MM-DD'),
        accounting_client_id: accountingClientCompany.accounting_client_id,
        entries: newEntries.map((entry) => ({
          account_id: entry.account_id,
          amount: parseFloat(entry.amount),
          entry_type: entry.entry_type,
        })),
      };

      // Call backend endpoint
      await axios.post(
        `${API_domain}createTransaction`,
        transactionData,
        {
          auth: {
            username: auth.email,
            password: auth.token,
          },
        }
      );

      message.success('Transacción creada exitosamente');

      // Refresh transactions list
      fetchTransactions();

      // Reset states
      setIsCreateModalVisible(false);
      setNewTransactionDescription('');
      setNewEntries([]);
      setNewTransactionDate(moment());
    } catch (error) {
      console.error('Error creating transaction:', error);
      message.error('Error al crear la transacción');
    }
  };

  const transactionColumns = [
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Fecha de Transacción',
      dataIndex: 'transaction_date',
      key: 'transaction_date',
    },
    {
      title: 'Fecha de Creación',
      dataIndex: 'created_time',
      key: 'created_time',
    },
    {
      title: 'Zoho Journal ID',
      dataIndex: 'zoho_journal_id',
      key: 'zoho_journal_id',
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => (
        <Popconfirm
          title='¿Seguro que deseas eliminar esta transacción?'
          onConfirm={() => handleDeleteTransaction(record.transaction_id)}
          okText='Sí'
          cancelText='No'
        >
          <Button type='danger' icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ];

  const entryColumns = [
    {
      title: 'Entry ID',
      dataIndex: 'entry_id',
      key: 'entry_id',
    },
    {
      title: 'Cuenta',
      dataIndex: 'account_id',
      key: 'account_id',
      render: (text, record) => (
        <AccountingAccountSelect
          value={record.account_id}
          onChange={(value) => handleAccountChange(record.entry_id, value)}
          clientId={accountingClientCompany.accounting_client_id}
          API_domain={API_domain}
          auth={auth}
        />
      ),
    },
    {
      title: 'Monto',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Tipo de Entrada',
      dataIndex: 'entry_type',
      key: 'entry_type',
    },
  ];

  const newEntryColumns = [
    {
      title: 'Cuenta',
      dataIndex: 'account_id',
      key: 'account_id',
      width: '200px',
      render: (text, record) => (
        <AccountingAccountSelect
          value={record.account_id}
          onChange={(value) => handleNewEntryChange(record.key, 'account_id', value)}
          clientId={accountingClientCompany.accounting_client_id}
          API_domain={API_domain}
          auth={auth}
        />
      ),
    },
    {
      title: 'Monto',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => (
        <Input
          value={record.amount}
          onChange={(e) => handleNewEntryChange(record.key, 'amount', e.target.value)}
        />
      ),
    },
    {
      title: 'Tipo de Entrada',
      dataIndex: 'entry_type',
      key: 'entry_type',
      render: (text, record) => (
        <Select
          value={record.entry_type}
          onChange={(value) => handleNewEntryChange(record.key, 'entry_type', value)}
          style={{ width: 100 }}
        >
          <Select.Option value='D'>Débito</Select.Option>
          <Select.Option value='C'>Crédito</Select.Option>
        </Select>
      ),
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => (
        <Button type='danger' icon={<DeleteOutlined />} onClick={() => removeNewEntry(record.key)} />
      ),
    },
  ];

  return (
    <>
      <Button type='primary' onClick={() => setIsCreateModalVisible(true)} style={{ marginBottom: 16 }}>
        Crear Transacción
      </Button>
      <Table
        dataSource={transactions}
        columns={transactionColumns}
        rowKey='transaction_id'
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
      />

      {/* Modal for viewing and editing entries of a transaction */}
      <Modal
        title={`Entradas de la Transacción ${selectedTransaction?.description}`}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSaveEntries}
        width={800}
      >
        <Table
          dataSource={entries}
          columns={entryColumns}
          rowKey='entry_id'
          pagination={false}
        />
      </Modal>

      {/* Modal for creating a new transaction */}
      <Modal
        title="Crear Nueva Transacción"
        visible={isCreateModalVisible}
        onCancel={() => setIsCreateModalVisible(false)}
        onOk={handleCreateTransaction}
        okButtonProps={{ disabled: totalDebits !== totalCredits || newEntries.length === 0 }}
        width={800}
      >
        <Input
          placeholder="Descripción de la Transacción"
          value={newTransactionDescription}
          onChange={(e) => setNewTransactionDescription(e.target.value)}
          style={{ marginBottom: 16 }}
        />
        <DatePicker
          value={newTransactionDate}
          onChange={(date) => setNewTransactionDate(date)}
          style={{ marginBottom: 16, marginLeft: 16 }}
        />
        <Table
          dataSource={newEntries}
          columns={newEntryColumns}
          rowKey='key'
          pagination={false}
        />
        <Button type='dashed' onClick={addNewEntry} style={{ marginTop: 16 }}>
          Añadir Entrada
        </Button>
        <div style={{ marginTop: 16 }}>
          <p>Total Débitos: {totalDebits}</p>
          <p>Total Créditos: {totalCredits}</p>
        </div>
      </Modal>
    </>
  );
}

export { AccountingTransactionsTable };
