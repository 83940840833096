import {
  BookFilled,
  BugFilled,
  FolderFilled,
  InteractionOutlined,
  CreditCardOutlined,
  LogoutOutlined,
  PieChartFilled,
} from '@ant-design/icons';
import { Checkbox, Layout, Menu, Select, Tooltip } from 'antd';
import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ShareHeroButton } from './ShareHeroButton.js';
import styled from 'styled-components/macro';
import { authContext } from '../ProvideAuth.js';
import { AdminGetAccountingClientUserLoginLink } from './Admin/AdminGetAccountingClientUserLoginLink';

const { Sider } = Layout;
const { Option } = Select;

const adminEmails = [
  'noreplyherofacturas@gmail.com',
  'jarol@herofacturas.com',
  'jorge@herofacturas.com',
  'miguel@herofacturas.com',
];

const accountingEmails = {
  'noreplyherofacturas@gmail.com': 100,
  'jarol@herofacturas.com': 100,
  'jorge@herofacturas.com': 100,
};

function isPrime(num) {
  if (num <= 1) return false;
  if (num <= 3) return true;

  if (num % 2 === 0 || num % 3 === 0) return false;

  for (let i = 5; i * i <= num; i += 6) {
    if (num % i === 0 || num % (i + 2) === 0) return false;
  }
  return true;
}

function dayOfYear(date) {
  const start = new Date(date.getFullYear(), 0, 0);
  const diff = date - start;
  const oneDay = 1000 * 60 * 60 * 24;
  return Math.floor(diff / oneDay);
}

const today = new Date();
const todayOfYear = dayOfYear(today);

const HomeSiderAccountant = (props) => {
  console.log('HomeSiderAccountant');
  const auth = useContext(authContext);
  const [showExpManFeatures, setShowExpManFeatures] = useState(true);
  const location = useLocation();

  const reloadIfSameUrl = (path) => {
    if (path === window.location.pathname) {
      window.location.reload();
    }
  };

  // Function to match the current path with the menu keys
  const pathMatch = (path) => {
    const paths = [
      '/expenses',
      '/reports',
      '/cards',
      '/visualizations',
      '/config',
      '/admin',
      '/dashboard',
      '/accounting',
      '/bank',
      '/accountingInbox',
      '/transactions',
      '/conciliation',
      '/conciliationv2', // Added here
      '/hr',
      '/paperMovementsView',
      '/moneyMovementsView',
      '/adminDashboard',
    ];
    return paths.includes(path) ? path : '/'; // Default to '/' or any default route
  };

  const selectedKey = [pathMatch(location.pathname)];

  return (
    <Sider
      style={{
        height: '100vh',
        position: 'fixed',
        left: 0,
        borderRight: '1px solid #f0f0f0',
      }}
      collapsedWidth='60'
      breakpoint='sm'
    >
      <Link
        to='/accounting'
        onClick={() => {
          reloadIfSameUrl('/accounting');
        }}
      >
        <Logo
          src='heroLogoTransparent.png'
          style={{ width: '80px', height: '80px' }}
        />
      </Link>
      <div
        style={{
          overflow: 'auto',
          height: 'calc(100vh - 200px)',
        }}
      >
        <Menu
          mode='inline'
          selectedKeys={selectedKey}
          style={{ backgroundColor: 'rgb(247,249,252)', borderRight: '0px' }}
        >
          <Menu.Item
            key='/accountingInbox'
            icon={
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                className='homesider-icon'
                width={18}
              >
                <path
                  fillRule='evenodd'
                  d='M6.912 3a3 3 0 0 0-2.868 2.118l-2.411 7.838a3 3 0 0 0-.133.882V18a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3v-4.162c0-.299-.045-.596-.133-.882l-2.412-7.838A3 3 0 0 0 17.088 3H6.912Zm13.823 9.75-2.213-7.191A1.5 1.5 0 0 0 17.088 4.5H6.912a1.5 1.5 0 0 0-1.434 1.059L3.265 12.75H6.11a3 3 0 0 1 2.684 1.658l.256.513a1.5 1.5 0 0 0 1.342.829h3.218a1.5 1.5 0 0 0 1.342-.83l.256-.512a3 3 0 0 1 2.684-1.658h2.844Z'
                  clipRule='evenodd'
                />
              </svg>
            }
            style={{
              marginTop: 8,
              marginBottom: 5,
              fontSize: 'max(1.16em,14px)',
            }}
          >
            <span>Inbox</span>
            <Link to='/accountingInbox' />
          </Menu.Item>

          <Menu.Item
            key='/accounting'
            icon={
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                className='homesider-icon'
                width={18}
              >
                <path
                  fillRule='evenodd'
                  d='M5.625 1.5H9a3.75 3.75 0 0 1 3.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 0 1 3.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 0 1-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875ZM9.75 17.25a.75.75 0 0 0-1.5 0V18a.75.75 0 0 0 1.5 0v-.75Zm2.25-3a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0v-3a.75.75 0 0 1 .75-.75Zm3.75-1.5a.75.75 0 0 0-1.5 0V18a.75.75 0 0 0 1.5 0v-5.25Z'
                  clipRule='evenodd'
                />
                <path d='M14.25 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 16.5 7.5h-1.875a.375.375 0 0 1-.375-.375V5.25Z' />
              </svg>
            }
            style={{
              marginTop: 8,
              marginBottom: 5,
              fontSize: 'max(1.16em,14px)',
            }}
          >
            <span>Contabilidad</span>
            <Link to='/accounting' />
          </Menu.Item>

          <Menu.Item
            key='/paperMovementsView'
            icon={
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                className='homesider-icon'
                width={18}
              >
                <path
                  fillRule='evenodd'
                  d='M4.125 3C3.089 3 2.25 3.84 2.25 4.875V18a3 3 0 0 0 3 3h15a3 3 0 0 1-3-3V4.875C17.25 3.839 16.41 3 15.375 3H4.125ZM12 9.75a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5H12Zm-.75-2.25a.75.75 0 0 1 .75-.75h1.5a.75.75 0 0 1 0 1.5H12a.75.75 0 0 1-.75-.75ZM6 12.75a.75.75 0 0 0 0 1.5h7.5a.75.75 0 0 0 0-1.5H6Zm-.75 3.75a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.75-.75ZM6 6.75a.75.75 0 0 0-.75.75v3c0 .414.336.75.75.75h3a.75.75 0 0 0 .75-.75v-3A.75.75 0 0 0 9 6.75H6Z'
                  clipRule='evenodd'
                />
                <path d='M18.75 6.75h1.875c.621 0 1.125.504 1.125 1.125V18a1.5 1.5 0 0 1-3 0V6.75Z' />
              </svg>
            }
            style={{
              marginTop: 8,
              marginBottom: 5,
              fontSize: 'max(1.16em,14px)',
            }}
          >
            <span>Registros</span>
            <Link to='/paperMovementsView' />
          </Menu.Item>

          <Menu.Item
            key='/moneyMovementsView'
            icon={
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                className='homesider-icon'
                width={18}
              >
                <path d='M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 0 1-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004ZM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 0 1-.921.42Z' />
                <path
                  fillRule='evenodd'
                  d='M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v.816a3.836 3.836 0 0 0-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 0 1-.921-.421l-.879-.66a.75.75 0 0 0-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 0 0 1.5 0v-.81a4.124 4.124 0 0 0 1.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 0 0-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 0 0 .933-1.175l-.415-.33a3.836 3.836 0 0 0-1.719-.755V6Z'
                  clipRule='evenodd'
                />
              </svg>
            }
            style={{
              marginTop: 8,
              marginBottom: 5,
              fontSize: 'max(1.16em,14px)',
            }}
          >
            <span>Transacciones</span>
            <Link to='/moneyMovementsView' />
          </Menu.Item>

          <Menu.Item
            key='/hr'
            icon={
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                className='homesider-icon'
                width={18}
              >
                <path
                  fillRule='evenodd'
                  d='M8.25 6.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM15.75 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM2.25 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM6.31 15.117A6.745 6.745 0 0 1 12 12a6.745 6.745 0 0 1 6.709 7.498.75.75 0 0 1-.372.568A12.696 12.696 0 0 1 12 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 0 1-.372-.568 6.787 6.787 0 0 1 1.019-4.38Z'
                  clipRule='evenodd'
                />
                <path d='M5.082 14.254a8.287 8.287 0 0 0-1.308 5.135 9.687 9.687 0 0 1-1.764-.44l-.115-.04a.563.563 0 0 1-.373-.487l-.01-.121a3.75 3.75 0 0 1 3.57-4.047ZM20.226 19.389a8.287 8.287 0 0 0-1.308-5.135 3.75 3.75 0 0 1 3.57 4.047l-.01.121a.563.563 0 0 1-.373.486l-.115.04c-.567.2-1.156.349-1.764.441Z' />
              </svg>
            }
            style={{
              marginTop: 8,
              marginBottom: 5,
              fontSize: 'max(1.16em,14px)',
            }}
          >
            <span>Planilla</span>
            <Link to='/hr' />
          </Menu.Item>

          <Menu.Item
            key='/cards'
            icon={
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                className='homesider-icon'
                width={18}
              >
                <path d='M4.5 3.75a3 3 0 0 0-3 3v.75h21v-.75a3 3 0 0 0-3-3h-15Z' />
                <path
                  fillRule='evenodd'
                  d='M22.5 9.75h-21v7.5a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3v-7.5Zm-18 3.75a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5h-6a.75.75 0 0 1-.75-.75Zm.75 2.25a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Z'
                  clipRule='evenodd'
                />
              </svg>
            }
            style={{
              marginTop: 8,
              marginBottom: 5,
              fontSize: 'max(1.16em,14px)',
            }}
          >
            <span>Tarjetas</span>
            <Link to='/cards' />
          </Menu.Item>

          <Menu.Item
            key='/config'
            icon={
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                className='homesider-icon'
                width={18}
              >
                <path
                  fillRule='evenodd'
                  d='M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 0 0-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 0 0-2.282.819l-.922 1.597a1.875 1.875 0 0 0 .432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 0 0 0 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 0 0-.432 2.385l.922 1.597a1.875 1.875 0 0 0 2.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 0 0 2.28-.819l.923-1.597a1.875 1.875 0 0 0-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 0 0 0-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 0 0-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 0 0-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 0 0-1.85-1.567h-1.843ZM12 15.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z'
                  clipRule='evenodd'
                />
              </svg>
            }
            style={{
              marginTop: 30,
              marginBottom: 30,
              fontSize: 'max(1.16em,14px)',
            }}
          >
            <span className='fourthStep'>Configuración</span>
            <Link to='/config' />
          </Menu.Item>

          {/* Conditional Menu Items */}
          {isPrime(todayOfYear) &&
            auth.showAccountingFeatures &&
            accountingEmails[auth.email] > 0 && (
              <Menu.Item
                style={{
                  marginTop: 8,
                  marginBottom: 5,
                  fontSize: 'max(1.16em,14px)',
                }}
              >
                <ShareHeroButton
                  API_domain={props.API_domain}
                  mobile={props.mobile}
                  amount={accountingEmails[auth.email]}
                />
              </Menu.Item>
            )}

          {/* Checkbox to toggle features */}
          <div style={{ marginLeft: 24, marginTop: 8 }}>
            <Checkbox
              onChange={(e) => {auth.moveAdminEmail(e.target.checked)}}
              checked={auth.adminEmail}
            >
              🦸‍♂️
            </Checkbox>
          </div>

          {auth.adminEmail && (
            <>
              <Menu.Item
                key='/expenses'
                icon={
                  <BookFilled
                    style={{
                      fontSize: 'max(1.05em,14px)',
                      marginRight: 15,
                      color: 'rgb(100,100,100)',
                    }}
                  />
                }
                style={{
                  marginTop: 24,
                  marginBottom: 5,
                  fontSize: 'max(1.16em,14px)',
                }}
              >
                <span className='firstStep'>Gastos</span>
                <Link
                  to='/expenses'
                  onClick={() => reloadIfSameUrl('/expenses')}
                />
              </Menu.Item>

              <Menu.Item
                key='/reports'
                icon={
                  <FolderFilled
                    style={{
                      fontSize: 'max(1.05em,14px)',
                      marginRight: 15,
                      color: 'rgb(100,100,100)',
                    }}
                  />
                }
                style={{
                  marginTop: 8,
                  marginBottom: 5,
                  fontSize: 'max(1.16em,14px)',
                }}
              >
                <span className='thirdStep'>Reportes</span>
                <Link
                  to='/reports'
                  onClick={() => reloadIfSameUrl('/reports')}
                />
              </Menu.Item>

              <div style={{ marginLeft: 24, marginTop: 24 }}>Tarjetas</div>

              <Menu.Item
                key='/transactions'
                icon={
                  <CreditCardOutlined
                    style={{
                      fontSize: 'max(1.05em,14px)',
                      marginRight: 10,
                      color: 'rgb(100,100,100)',
                    }}
                  />
                }
                style={{
                  marginTop: 8,
                  marginBottom: 5,
                  fontSize: 'max(1.16em,14px)',
                  paddingLeft: '40px',
                }}
              >
                <span className='firstStep'>Transacciones</span>
                <Link
                  to='/transactions'
                  onClick={() => reloadIfSameUrl('/transactions')}
                />
              </Menu.Item>

              <Menu.Item
                key='/conciliation'
                icon={
                  <InteractionOutlined
                    style={{
                      fontSize: 'max(1.05em,14px)',
                      marginRight: 10,
                      color: 'rgb(100,100,100)',
                    }}
                  />
                }
                style={{
                  marginTop: 8,
                  marginBottom: 5,
                  fontSize: 'max(1.16em,14px)',
                  paddingLeft: '40px',
                }}
              >
                <span className='firstStep'>Conciliación</span>
                <Link
                  to='/conciliation'
                  onClick={() => reloadIfSameUrl('/conciliation')}
                />
              </Menu.Item>

              <Menu.Item
                key='/conciliationv2'
                icon={
                  <InteractionOutlined
                    style={{
                      fontSize: 'max(1.05em,14px)',
                      marginRight: 10,
                      color: 'rgb(100,100,100)',
                    }}
                  />
                }
                style={{
                  marginTop: 8,
                  marginBottom: 5,
                  fontSize: 'max(1.16em,14px)',
                  paddingLeft: '40px',
                }}
              >
                <span className='firstStep'>Conciliación v2</span>
                <Link
                  to='/conciliationv2'
                  onClick={() => reloadIfSameUrl('/conciliationv2')}
                />
              </Menu.Item>

              <Menu.Item
                key='/visualizations'
                icon={
                  <PieChartFilled
                    style={{
                      fontSize: 'max(1.05em,14px)',
                      marginRight: 15,
                      color: 'rgb(100,100,100)',
                    }}
                  />
                }
                style={{
                  marginTop: 24,
                  marginBottom: 5,
                  fontSize: 'max(1.16em,14px)',
                }}
              >
                <span>Visualizaciones</span>
                <Link to='/visualizations' />
              </Menu.Item>

              <Menu.Item
                key='/adminDashboard'
                icon={
                  <BugFilled
                    style={{
                      fontSize: 'max(1.05em,14px)',
                      marginRight: 15,
                      color: 'rgb(100,100,100)',
                    }}
                  />
                }
                style={{
                  marginTop: 8,
                  marginBottom: 5,
                  fontSize: 'max(1.16em,14px)',
                }}
              >
                <span>Admin</span>
                <Link to='/adminDashboard' />
              </Menu.Item>
            </>
          )}
        </Menu>
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: 4,
          width: '100%',
          backgroundColor: 'rgb(247,249,252)',
          borderTop: '1px solid #f0f0f0',
        }}
      >
        {auth.adminEmail && adminEmails.includes(auth.adminEmail) && (
          <div style={{ marginTop: 8, width: '200px ' }}>
            <AdminGetAccountingClientUserLoginLink
              API_domain={props.API_domain}
            />
          </div>
        )}
        {auth.accounts.length > 1 && (
          <Select
            showSearch
            defaultValue={auth.email}
            style={{ width: 'calc(100% - 50px)', marginTop: 8 }}
            onChange={(value) => auth.switchAccount(value)}
            placement='topLeft'
            dropdownMatchSelectWidth={false}
          >
            {auth.accounts.map((account) => (
              <Option key={account.email} value={account.email}>
                {account.email}
              </Option>
            ))}
          </Select>
        )}
        <Tooltip title={`Logout ${auth.email}`}>
          <LogoutOutlined
            onClick={() => auth.signout(auth.email)}
            style={{
              fontSize: '20px',
              color: 'rgb(100,100,100)',
              cursor: 'pointer',
              marginLeft: auth.accounts.length > 1 ? '10px' : '0',
              marginTop: 8,
            }}
          />
        </Tooltip>
      </div>
    </Sider>
  );
};

const Logo = styled.img`
  margin-top: 16px;
  margin-bottom: 20px;
  cursor: pointer;
`;

export { HomeSiderAccountant };
