import React from 'react';
import { Button, message } from 'antd';
import axios from 'axios';

function CommitSotTransactionsButton({
  selectedSotTxnsInMassEdit,
  API_domain,
  auth,
  setUploadChanged,
  uploadChanged,
  setLoading,
}) {
  const commitSotTransactions = async () => {
    setLoading(true);
    axios({
      method: 'post',
      url: `${API_domain}/commitSotTransactions`,
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        sot_transactions: selectedSotTxnsInMassEdit, // Pass the selected transactions
      },
    })
      .then((response) => {
        message.success('Transactions committed successfully!');
        setUploadChanged(!uploadChanged); // Refresh data after committing
        setLoading(false);
      })
      .catch((error) => {
        message.error('Failed to commit transactions. Please try again.');
        setLoading(false);
      });
  };

  return (
    <Button
      type="primary"
      onClick={commitSotTransactions}
      disabled={selectedSotTxnsInMassEdit.length === 0}
      style={{ fontWeight: 500 }}
    >
      Crear Diarios 🦸‍♂️
    </Button>
  );
}

export default CommitSotTransactionsButton;
