// InvuTable.js
import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Button, Layout, Table, message, Tag, Tooltip, Input } from 'antd';
import styled from 'styled-components/macro';
import axios from 'axios';
import { authContext } from '../ProvideAuth.js';
import moment from 'moment'; // Import moment for date formatting
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx'; // Import XLSX for CSV export
import { FormattedUSD } from './FormattedUSD.js';
import { DateFilterDropdown } from './DateFilterDropdown.js';
import { get_moments_from_month_name } from '../utils.js';
import FEbutton from './FEbuttonAndModal.js'; // Import the FEbutton component

const { Content } = Layout;

function InvuTable(props) {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const auth = useContext(authContext);

  // State to keep track of current table filters
  const [currentFilters, setCurrentFilters] = useState({
    fecha_cierre_date: null, // Can be a range or a month name
    payment_type: null, // Array of selected payment types
    client_config_row_id: null, // Array of selected client config IDs
    numero_factura: null, // Added for filtering 'numero_factura' (CUFE)
  });

  // Fetch orders on component mount
  useEffect(() => {
    const fetchOrders = () => {
      setLoading(true);

      axios({
        method: 'post',
        url: props.API_domain + 'getInvuOrdersForAccountingClient',
        auth: {
          username: auth.email,
          password: auth.token,
        },
        data: {},
      })
        .then((response) => {
          if (response.status === 200 && response.data.invu_orders) {
            setOrders(response.data.invu_orders);
          } else {
            message.error('Failed to fetch orders');
          }
        })
        .catch((error) => {
          console.error('Error fetching orders:', error);
          message.error('An error occurred while fetching orders');
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchOrders();
  }, [props.API_domain, auth.email, auth.token]); // Added auth dependencies

  // Function to export table data to CSV
  const exportToCSV = () => {
    // Apply current filters to the orders data
    let filteredOrders = [...orders];

    // Apply Fecha de Cierre filter
    if (
      currentFilters.fecha_cierre_date &&
      currentFilters.fecha_cierre_date.length > 0
    ) {
      const value = currentFilters.fecha_cierre_date[0]; // The selected value
      if (
        Array.isArray(value) &&
        value.length === 2 &&
        moment.isMoment(value[0]) &&
        moment.isMoment(value[1])
      ) {
        const [start, end] = value;
        filteredOrders = filteredOrders.filter((order) =>
          moment(order.fecha_cierre_date).isBetween(start, end, 'day', '[]')
        );
      } else if (typeof value === 'string') {
        const filterDates = get_moments_from_month_name(value);
        if (filterDates) {
          filteredOrders = filteredOrders.filter((order) =>
            moment(order.fecha_cierre_date).isBetween(
              filterDates[0],
              filterDates[1],
              'day',
              '[]'
            )
          );
        }
      }
    }

    // Apply Forma de Pago filter
    if (currentFilters.payment_type && currentFilters.payment_type.length > 0) {
      filteredOrders = filteredOrders.filter((order) =>
        order.payments.some((payment) =>
          currentFilters.payment_type.includes(payment.payment_type)
        )
      );
    }

    // Apply Client Config filter
    if (
      currentFilters.client_config_row_id &&
      currentFilters.client_config_row_id.length > 0
    ) {
      filteredOrders = filteredOrders.filter((order) =>
        currentFilters.client_config_row_id.includes(order.client_config_row_id)
      );
    }

    // Apply Número de Factura filter
    if (
      currentFilters.numero_factura &&
      currentFilters.numero_factura.length > 0
    ) {
      const value = currentFilters.numero_factura[0];
      filteredOrders = filteredOrders.filter((order) =>
        order.numero_factura
          ? order.numero_factura
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : false
      );
    }

    if (!filteredOrders || filteredOrders.length === 0) {
      message.warning('No hay datos para exportar.');
      return;
    }

    // Define headers based on your current table columns
    const headers = [
      'Fecha de Cierre',
      'Número de Factura',
      'Forma de Pago',
      'Impuesto',
      'Total',
      'Client Config 🦸‍♂️',
    ];

    // Map data to rows
    const data = filteredOrders.map((order) => {
      const row = [];

      // Fecha de Cierre
      row.push(
        order.fecha_cierre_date
          ? moment(order.fecha_cierre_date).format('YYYY-MM-DD')
          : ''
      );

      // Número de Factura
      row.push(order.numero_factura ? order.numero_factura : '');

      // Forma de Pago
      row.push(order.payments.length > 0 ? order.payments[0].payment_type : '');

      // Impuesto
      row.push(
        order.tax !== null && order.tax !== undefined
          ? order.tax.toFixed(2)
          : '0.00'
      );

      // Total
      row.push(
        order.total !== null && order.total !== undefined
          ? order.total.toFixed(2)
          : '0.00'
      );

      // Client Config
      row.push(order.client_config_row_id ? order.client_config_row_id : '');

      return row;
    });

    // Combine headers and data
    const csvData = [headers, ...data];

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(csvData);

    // Create a workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Orders');

    // Generate a binary string representation of the workbook
    const wbout = XLSX.write(wb, { bookType: 'csv', type: 'array' });

    // Create a Blob from the binary string
    const blob = new Blob([wbout], { type: 'text/csv;charset=utf-8;' });

    // Create a link to download the Blob
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;

    // Name the file with current date
    const currentDate = moment().format('YYYYMMDD_HHmmss');
    a.download = `invu_orders_${currentDate}.csv`;
    a.click();
    URL.revokeObjectURL(url);

    message.success('Tabla exportada exitosamente.');
  };

  // Function to capitalize payment types
  const capitalizePaymentType = (paymentType) => {
    return paymentType
      .toLowerCase()
      .replace(/\b\w/g, (c) => c.toUpperCase())
      .replace(/\./g, '');
  };

  // Compute unique payment types for filters
  const paymentTypeFilters = useMemo(() => {
    const paymentTypesSet = new Set();
    orders.forEach((order) => {
      order.payments.forEach((payment) => {
        const paymentType = payment.payment_type;
        paymentTypesSet.add(paymentType);
      });
    });
    const filters = Array.from(paymentTypesSet).map((type) => ({
      text: capitalizePaymentType(type),
      value: type,
    }));
    return filters;
  }, [orders]);

  // Compute unique client_config_row_id values for filters
  const clientConfigFilters = useMemo(() => {
    const clientConfigSet = new Set();
    orders.forEach((order) => {
      if (order.client_config_row_id) {
        clientConfigSet.add(order.client_config_row_id);
      }
    });
    const filters = Array.from(clientConfigSet).map((id) => ({
      text: id.toString(),
      value: id,
    }));
    return filters;
  }, [orders]);

  const columns = [
    {
      title: 'Fecha de Cierre',
      dataIndex: 'fecha_cierre_date',
      key: 'fecha_cierre_date',
      width: 240, // Adjust width as needed
      sorter: (a, b) =>
        moment(a.fecha_cierre_date).unix() - moment(b.fecha_cierre_date).unix(),
      render: (text) => moment(text).format('YYYY-MM-DD'),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <DateFilterDropdown
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        // [Your existing filtering logic]
      },
      filteredValue: currentFilters.fecha_cierre_date || null,
    },
    {
      title: 'CUFE',
      dataIndex: 'numero_factura',
      key: 'numero_factura',
      render: (text) => (
        <FEbutton
          numero_factura={text}
          API_domain={props.API_domain}
          auth={auth}
        />
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder='Buscar CUFE'
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm({ closeDropdown: true });
            }}
            size='small'
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </div>
      ),
      onFilter: (value, record) => {
        const searchValue = value.toLowerCase();
        const numeroFactura = record.numero_factura
          ? record.numero_factura.toString().toLowerCase()
          : '';
        return numeroFactura.includes(searchValue);
      },
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filteredValue: currentFilters.numero_factura || null,
    },
    {
      title: 'Forma de pago',
      dataIndex: 'payments',
      key: 'payments',
      render: (payments, record) => (
        <span style={{ textTransform: 'capitalize' }}>
          {payments.length > 0
            ? capitalizePaymentType(payments[0].payment_type)
            : ''}
          {record.pagada !== 'Cerrada' && (
            <Tag color='red' style={{ marginLeft: 4 }}>
              {record.pagada}
            </Tag>
          )}
        </span>
      ),
      filters: paymentTypeFilters,
      onFilter: (value, record) =>
        record.payments.some((payment) => payment.payment_type === value),
      filteredValue: currentFilters.payment_type || null,
    },
    {
      title: 'Impuesto',
      dataIndex: 'tax',
      key: 'tax',
      render: (value) => <FormattedUSD total={value.toFixed(2)} />,
      sorter: (a, b) => a.tax - b.tax,
      align: 'right',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (value) => <FormattedUSD total={value.toFixed(2)} />,
      sorter: (a, b) => a.total - b.total,
      align: 'right',
    },
    ...(auth.adminEmail
      ? [
          {
            title: 'Client Config 🦸‍♂️',
            dataIndex: 'client_config_row_id',
            key: 'client_config_row_id',
            width: 160,
            filters: clientConfigFilters,
            onFilter: (value, record) => record.client_config_row_id === value,
            render: (text) => text,
            filteredValue: currentFilters.client_config_row_id || null,
          },
        ]
      : []),
  ];

  // Handler for table changes (filters, sorter, pagination)
  const handleTableChange = (pagination, filters, sorter) => {
    setCurrentFilters({
      fecha_cierre_date: filters.fecha_cierre_date || null,
      payment_type: filters.payments || null,
      client_config_row_id: filters.client_config_row_id || null,
      numero_factura: filters.numero_factura || null,
    });
  };

  return (
    <Content
      style={{
        margin: '0 0 0',
        overflow: 'initial',
        borderRight: 'solid rgb(235,235,235) 1px',
        borderTop: 'solid rgb(235,235,235) 1px',
      }}
    >
      <AffixDiv style={{ maxWidth: '1400px', margin: 'auto' }}>
        {/* Add the Export Button here */}
        <div style={{ textAlign: 'right' }}>
          <Button
            type='default'
            icon={<DownloadOutlined />}
            onClick={exportToCSV}
            style={{ marginBottom: '16px' }}
          >
            Excel
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={orders}
          rowKey='id'
          loading={loading}
          pagination={{ pageSize: 100 }}
          onChange={handleTableChange}
        />
      </AffixDiv>
    </Content>
  );
}

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
`;

export { InvuTable };
