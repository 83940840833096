// PedidosYaTable.js
import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Button, Layout, Table, message, Input } from 'antd';
import styled from 'styled-components/macro';
import axios from 'axios';
import { authContext } from '../ProvideAuth.js';
import moment from 'moment'; // Import moment for date formatting
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx'; // Import XLSX for CSV export
import { FormattedUSD } from './FormattedUSD.js';
import { DateFilterDropdown } from './DateFilterDropdown.js';
import { get_moments_from_month_name } from '../utils.js';

const { Content } = Layout;

function PedidosYaTable(props) {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const auth = useContext(authContext);

  // State to keep track of current table filters
  const [currentFilters, setCurrentFilters] = useState({
    fecha_del_pedido: null,
    estado_del_pedido: null,
    nombre_del_local: null,
    forma_de_pago: null,
    medio_de_pago: null,
  });

  // Fetch orders on component mount
  useEffect(() => {
    const fetchOrders = () => {
      setLoading(true);

      axios({
        method: 'post',
        url: props.API_domain + 'getPedidosYaOrdersForAccountingClient',
        auth: {
          username: auth.email,
          password: auth.token,
        },
        data: {},
      })
        .then((response) => {
          console.log(response.data);
          if (response.status === 200 && response.data.pedidos_ya_orders) {
            setOrders(response.data.pedidos_ya_orders);
          } else {
            message.error('Failed to fetch orders');
          }
        })
        .catch((error) => {
          console.error('Error fetching orders:', error);
          message.error('An error occurred while fetching orders');
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchOrders();
  }, [props.API_domain, auth.email, auth.token]);

  // Function to export table data to CSV
  const exportToCSV = () => {
    // Apply current filters to the orders data
    let filteredOrders = [...orders];

    // Apply Fecha del Pedido filter
    if (
      currentFilters.fecha_del_pedido &&
      currentFilters.fecha_del_pedido.length > 0
    ) {
      const value = currentFilters.fecha_del_pedido[0]; // The selected value
      if (
        Array.isArray(value) &&
        value.length === 2 &&
        moment.isMoment(value[0]) &&
        moment.isMoment(value[1])
      ) {
        const [start, end] = value;
        filteredOrders = filteredOrders.filter((order) =>
          moment(order.fecha_del_pedido).isBetween(start, end, 'day', '[]')
        );
      } else if (typeof value === 'string') {
        const filterDates = get_moments_from_month_name(value);
        if (filterDates) {
          filteredOrders = filteredOrders.filter((order) =>
            moment(order.fecha_del_pedido).isBetween(
              filterDates[0],
              filterDates[1],
              'day',
              '[]'
            )
          );
        }
      }
    }

    // Apply Estado del Pedido filter
    if (
      currentFilters.estado_del_pedido &&
      currentFilters.estado_del_pedido.length > 0
    ) {
      filteredOrders = filteredOrders.filter((order) =>
        currentFilters.estado_del_pedido.includes(order.estado_del_pedido)
      );
    }

    // Apply Nombre del Local filter
    if (
      currentFilters.nombre_del_local &&
      currentFilters.nombre_del_local.length > 0
    ) {
      const value = currentFilters.nombre_del_local[0].toLowerCase();
      filteredOrders = filteredOrders.filter((order) =>
        order.nombre_del_local.toLowerCase().includes(value)
      );
    }

    // Apply Forma de Pago filter
    if (
      currentFilters.forma_de_pago &&
      currentFilters.forma_de_pago.length > 0
    ) {
      filteredOrders = filteredOrders.filter((order) =>
        currentFilters.forma_de_pago.includes(order.forma_de_pago)
      );
    }

    // Apply Medio de Pago filter
    if (
      currentFilters.medio_de_pago &&
      currentFilters.medio_de_pago.length > 0
    ) {
      filteredOrders = filteredOrders.filter((order) =>
        currentFilters.medio_de_pago.includes(order.medio_de_pago)
      );
    }

    if (!filteredOrders || filteredOrders.length === 0) {
      message.warning('No hay datos para exportar.');
      return;
    }

    // Define headers based on your current table columns
    const headers = [
      'Fecha del Pedido',
      'Estado del Pedido',
      'Nombre del Local',
      'Artículos',
      'Forma de Pago',
      'Medio de Pago',
      'Total del Pedido',
      'Servicio PedidosYa',
      'Tax Charge',
      'Ingreso Estimado',
    ];

    // Map data to rows
    const data = filteredOrders.map((order) => [
      moment(order.fecha_del_pedido).format('YYYY-MM-DD HH:mm:ss'),
      order.estado_del_pedido || '',
      order.nombre_del_local || '',
      order.articulos || '',
      order.forma_de_pago || '',
      order.medio_de_pago || '',
      order.total_del_pedido ? order.total_del_pedido.toFixed(2) : '0.00',
      order.servicio_pedidosya ? order.servicio_pedidosya.toFixed(2) : '0.00',
      order.tax_charge ? order.tax_charge.toFixed(2) : '0.00',
      order.ingreso_estimado ? order.ingreso_estimado.toFixed(2) : '0.00',
    ]);

    // Combine headers and data
    const csvData = [headers, ...data];

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(csvData);

    // Create a workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Orders');

    // Generate a binary string representation of the workbook
    const wbout = XLSX.write(wb, { bookType: 'csv', type: 'array' });

    // Create a Blob from the binary string
    const blob = new Blob([wbout], { type: 'text/csv;charset=utf-8;' });

    // Create a link to download the Blob
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;

    // Name the file with current date
    const currentDate = moment().format('YYYYMMDD_HHmmss');
    a.download = `pedidos_ya_orders_${currentDate}.csv`;
    a.click();
    URL.revokeObjectURL(url);

    message.success('Tabla exportada exitosamente.');
  };

  // Compute unique Estado del Pedido for filters
  const estadoDelPedidoFilters = useMemo(() => {
    const estadosSet = new Set();
    orders.forEach((order) => {
      if (order.estado_del_pedido) {
        estadosSet.add(order.estado_del_pedido);
      }
    });
    return Array.from(estadosSet).map((estado) => ({
      text: estado,
      value: estado,
    }));
  }, [orders]);

  // Compute unique Forma de Pago for filters
  const formaDePagoFilters = useMemo(() => {
    const formasSet = new Set();
    orders.forEach((order) => {
      if (order.forma_de_pago) {
        formasSet.add(order.forma_de_pago);
      }
    });
    return Array.from(formasSet).map((forma) => ({
      text: forma,
      value: forma,
    }));
  }, [orders]);

  // Compute unique Medio de Pago for filters
  const medioDePagoFilters = useMemo(() => {
    const mediosSet = new Set();
    orders.forEach((order) => {
      if (order.medio_de_pago) {
        mediosSet.add(order.medio_de_pago);
      }
    });
    return Array.from(mediosSet).map((medio) => ({
      text: medio,
      value: medio,
    }));
  }, [orders]);

  // Define columns
  const columns = [
    {
      title: 'Fecha del Pedido',
      dataIndex: 'fecha_del_pedido',
      key: 'fecha_del_pedido',
      width: 240, // Adjust width as needed
      sorter: (a, b) =>
        moment(a.fecha_del_pedido).unix() - moment(b.fecha_del_pedido).unix(),
      render: (text) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <DateFilterDropdown
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        // Handle date filter
        if (
          Array.isArray(value) &&
          value.length === 2 &&
          moment.isMoment(value[0]) &&
          moment.isMoment(value[1])
        ) {
          const [start, end] = value;
          return moment(record.fecha_del_pedido).isBetween(
            start,
            end,
            'day',
            '[]'
          );
        } else if (typeof value === 'string') {
          const filterDates = get_moments_from_month_name(value);
          if (filterDates) {
            return moment(record.fecha_del_pedido).isBetween(
              filterDates[0],
              filterDates[1],
              'day',
              '[]'
            );
          }
        }
        return true;
      },
      filteredValue: currentFilters.fecha_del_pedido || null,
    },
    {
      title: 'Estado del Pedido',
      dataIndex: 'estado_del_pedido',
      key: 'estado_del_pedido',
      filters: estadoDelPedidoFilters,
      onFilter: (value, record) => record.estado_del_pedido === value,
      filteredValue: currentFilters.estado_del_pedido || null,
    },
    // {
    //   title: 'Nombre del Local',
    //   dataIndex: 'nombre_del_local',
    //   key: 'nombre_del_local',
    //   filterDropdown: ({
    //     setSelectedKeys,
    //     selectedKeys,
    //     confirm,
    //     clearFilters,
    //   }) => (
    //     <div style={{ padding: 8 }}>
    //       <Input
    //         placeholder='Buscar Local'
    //         value={selectedKeys[0]}
    //         onChange={(e) =>
    //           setSelectedKeys(e.target.value ? [e.target.value] : [])
    //         }
    //         onPressEnter={() => confirm()}
    //         style={{ marginBottom: 8, display: 'block' }}
    //       />
    //       <Button
    //         type='primary'
    //         onClick={() => confirm()}
    //         icon={<SearchOutlined />}
    //         size='small'
    //         style={{ width: 90, marginRight: 8 }}
    //       >
    //         Buscar
    //       </Button>
    //       <Button
    //         onClick={() => {
    //           clearFilters();
    //           confirm({ closeDropdown: true });
    //         }}
    //         size='small'
    //         style={{ width: 90 }}
    //       >
    //         Limpiar
    //       </Button>
    //     </div>
    //   ),
    //   onFilter: (value, record) =>
    //     record.nombre_del_local.toLowerCase().includes(value.toLowerCase()),
    //   filterIcon: (filtered) => (
    //     <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    //   ),
    //   filteredValue: currentFilters.nombre_del_local || null,
    // },
    // {
    //   title: 'Artículos',
    //   dataIndex: 'articulos',
    //   key: 'articulos',
    // },
    {
      title: 'Forma de Pago',
      dataIndex: 'forma_de_pago',
      key: 'forma_de_pago',
      filters: formaDePagoFilters,
      onFilter: (value, record) => record.forma_de_pago === value,
      filteredValue: currentFilters.forma_de_pago || null,
    },
    {
      title: 'Medio de Pago',
      dataIndex: 'medio_de_pago',
      key: 'medio_de_pago',
      filters: medioDePagoFilters,
      onFilter: (value, record) => record.medio_de_pago === value,
      filteredValue: currentFilters.medio_de_pago || null,
    },
    {
      title: 'Total del Pedido',
      dataIndex: 'total_del_pedido',
      key: 'total_del_pedido',
      render: (value) => (
        <FormattedUSD total={value ? value.toFixed(2) : '0.00'} />
      ),
      sorter: (a, b) => a.total_del_pedido - b.total_del_pedido,
      align: 'right',
    },
    {
      title: 'Servicio PedidosYa',
      dataIndex: 'servicio_pedidosya',
      key: 'servicio_pedidosya',
      render: (value) => (
        <FormattedUSD total={value ? value.toFixed(2) : '0.00'} />
      ),
      sorter: (a, b) => a.servicio_pedidosya - b.servicio_pedidosya,
      align: 'right',
    },
    {
      title: 'Impuesto',
      dataIndex: 'tax_charge',
      key: 'tax_charge',
      render: (value) => (
        <FormattedUSD total={value ? value.toFixed(2) : '0.00'} />
      ),
      sorter: (a, b) => a.tax_charge - b.tax_charge,
      align: 'right',
    },
    {
      title: 'Ingreso Estimado',
      dataIndex: 'ingreso_estimado',
      key: 'ingreso_estimado',
      render: (value) => (
        <FormattedUSD total={value ? value.toFixed(2) : '0.00'} />
      ),
      sorter: (a, b) => a.ingreso_estimado - b.ingreso_estimado,
      align: 'right',
    },
  ];

  // Handler for table changes (filters, sorter, pagination)
  const handleTableChange = (pagination, filters, sorter) => {
    setCurrentFilters({
      fecha_del_pedido: filters.fecha_del_pedido || null,
      estado_del_pedido: filters.estado_del_pedido || null,
      nombre_del_local: filters.nombre_del_local || null,
      forma_de_pago: filters.forma_de_pago || null,
      medio_de_pago: filters.medio_de_pago || null,
    });
  };

  return (
    <Content
      style={{
        margin: '0 0 0',
        overflow: 'initial',
        borderRight: 'solid rgb(235,235,235) 1px',
        borderTop: 'solid rgb(235,235,235) 1px',
      }}
    >
      <AffixDiv style={{ maxWidth: '1400px', margin: 'auto' }}>
        {/* Add the Export Button here */}
        <div style={{ textAlign: 'right' }}>
          <Button
            type='default'
            icon={<DownloadOutlined />}
            onClick={exportToCSV}
            style={{ marginBottom: '16px' }}
          >
            Excel
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={orders}
          rowKey='id'
          loading={loading}
          pagination={{ pageSize: 100 }}
          onChange={handleTableChange}
        />
      </AffixDiv>
    </Content>
  );
}

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
`;

export { PedidosYaTable };
