import React, { useContext, useRef, useState, useEffect } from 'react';
import { Button, message, Menu, Dropdown, Tooltip, Select, Switch } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { ExistingPayrollTableTable } from './ExistingPayrollTableTable';
import axios from 'axios';
import { authContext } from '../../ProvideAuth';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { formatDateSpanishLong } from '../../utils';
import { FormattedUSD } from '../FormattedUSD';

import EmailReviewModal from '../EmailComposer/EmailReviewModal.js';
const { Option } = Select;

function ExistingPayrollTableContainer(props) {
  const auth = useContext(authContext);
  const [payrollDatesForSelect, setPayrollDatesForSelect] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    props.selectedPayroll ? props.selectedPayroll : null
  );
  const [payrollData, setPayrollData] = useState([]);
  const [isCondensed, setIsCondensed] = useState(
    props.isCondensed ? props.isCondensed : true
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [decimoTercerMesBoolean, setDecimoTercerMesBoolean] = useState(false);
  const [vacationBoolean, setVacationBoolean] = useState(false);
  const tableRef = useRef(null);
  const [payrollWithHoursBrokenDown, setPayrollWithHoursBrokenDown] =
    useState(false);
  const [deleting, setDeleting] = useState(false);
  const [renderAdvancePaymentOnly, setRenderAdvancePaymentOnly] =
    useState(false);
  function toggleView() {
    setIsCondensed(!isCondensed);
  }

  const handleRowClick = (record) => {
    setSelectedEmployee(record);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  const [isEmailEditModalVisible, setIsEmailEditModalVisible] = useState(false);
  const [emailData, setEmailData] = useState({});

  const loadImageBase64 = (base64, mimeType = 'image/png') => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = () => {
        console.warn(`Failed to load image`);
        resolve(null); // Resolve with null if image fails to load
      };
      // Set the src with the appropriate base64 data URL format
      img.src = `data:${mimeType};base64,${base64}`;
    });
  };
  const loadImage = (src) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = () => {
        console.warn(`Failed to load image: ${src}`);
        resolve(null); // Resolve with null if image fails to load
      };
      img.src = src;
    });
  };

  const exportPDF = async (
    payrollWithHoursBrokenDown,
    isCondensed,
    file_name = 'download_hero.pdf',
    outputOnlyBlob = false
  ) => {
    const doc = new jsPDF('landscape', 'pt', 'A4');
    doc.setFontSize(18);
    doc.setFont('Helvetica', 'bold');
    const title = `Planilla del ${formatDateSpanishLong(
      payrollData[0].payable_date
    )}${decimoTercerMesBoolean ? ' - Décimo Tercer Mes' : ''}${
      vacationBoolean ? ' - Vacaciones' : ''
    }`;

    doc.text(title, 40, 70);

    const loadImageBase64 = (base64, mimeType = 'image/png') => {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = () => {
          console.warn('Failed to load image');
          resolve(null); // Resolve with null if image fails to load
        };
        img.src = `data:${mimeType};base64,${base64}`;
      });
    };

    const loadImage = (src) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = () => {
          console.warn(`Failed to load image: ${src}`);
          resolve(null); // Resolve with null if image fails to load
        };
        img.src = src;
      });
    };

    const logo1 = await loadImageBase64(props.logoBase64);
    const logo2 = await loadImage('heroLogoSmall.png');

    const drawLogos = () => {
      const pageWidth = doc.internal.pageSize.getWidth();
      const yOffset = 20;
      const logoHeight = 50;
      let logoWidth1 = 0;
      let logoWidth2 = 0;
      let xOffset1 = pageWidth - 30; // Start from the right margin

      if (logo2) {
        const aspectRatio2 = logo2.width / logo2.height;
        logoWidth2 = logoHeight * aspectRatio2;
        xOffset1 -= logoWidth2;
        doc.addImage(
          logo2.src,
          'PNG',
          xOffset1,
          yOffset,
          logoWidth2,
          logoHeight
        );
      }

      if (logo1) {
        const aspectRatio1 = logo1.width / logo1.height;
        logoWidth1 = logoHeight * aspectRatio1;
        xOffset1 -= logoWidth1 + 10; // Adjust spacing between logos
        doc.addImage(
          logo1.src,
          'PNG',
          xOffset1,
          yOffset,
          logoWidth1,
          logoHeight
        );
      }
    };

    // Draw logos
    drawLogos();

    // Define horasColumns
    const horasColumns = [
      { key: 'salarioporhora', title: 'Salario por Hora' },
      { key: 'horas_extradiurnas', title: 'Horas Extra Diurnas' },
      { key: 'pago_extradiurnas', title: 'Pago Extra Diurnas' },
      { key: 'horas_extranocturnas', title: 'Horas Extra Nocturnas' },
      { key: 'pago_extranocturnas', title: 'Pago Extra Nocturnas' },
      { key: 'horas_domingos', title: 'Horas Domingos' },
      { key: 'pago_domingos', title: 'Pago Domingos' },
      { key: 'horas_dialibre', title: 'Horas Día Libre' },
      { key: 'pago_dialibre', title: 'Pago Día Libre' },
      { key: 'horas_recargo', title: 'Horas Recargo' },
      { key: 'pago_recargo', title: 'Pago Recargo' },
      { key: 'horas_no_trabajadas', title: 'Horas No Trabajadas' },
    ];

    const horasColumnKeys = horasColumns.map((col) => col.key);

    // Exclude columns (if any)
    const excludeColumns = [
      // Add any columns you want to exclude globally
    ];

    // Define columns based on isCondensed
    let columns;
    if (isCondensed) {
      columns = [
        { key: 'name', title: 'Colaborador' },
        { key: 'biweekly_salary', title: 'Sal. quincenal' },
        { key: 'benefit_production', title: 'Prima de Prod.' },
        { key: 'benefit_fuel', title: 'Prima de comb.' },
        { key: 'pago_total_extras', title: 'Horas Extra comp.' },
        { key: 'payroll_gross', title: 'Total de Ingresos' },
        { key: 'deduction_total', title: 'Deduc. totales' },
        { key: 'payroll_net', title: 'Sal. Neto a Recibir' },
        { key: 'notes', title: 'Notas' },
      ];
    } else {
      columns = [
        { key: 'name', title: 'Colaborador' },
        { key: 'biweekly_salary', title: 'Sal. quincenal' },
        { key: 'benefit_production', title: 'Prima de Prod.' },
        { key: 'benefit_fuel', title: 'Prima de comb.' },
        { key: 'benefit_representation_expenses', title: 'Gastos de Rep.' },
        { key: 'overtime', title: 'Horas extra simple' },
        { key: 'pago_total_extras', title: 'Horas Extra comp.' },
        { key: 'payroll_gross', title: 'Total de Ingresos' },
        { key: 'deduction_ss', title: 'Seguro Social' },
        { key: 'deduction_se', title: 'Seguro Educativo' },
        { key: 'deduction_isr', title: 'ISR' },
        { key: 'deduction_attendance', title: 'Deduc. de asistencia' },
        { key: 'advance_deductions', title: 'Deduc. de adelantos' },
        { key: 'deduction_total', title: 'Deduc. totales' },
        { key: 'payroll_net', title: 'Sal. Neto a Recibir' },
        { key: 'notes', title: 'Notas' },
        // Add other columns as needed
      ];
    }

    // Filter columns to exclude horasColumns and any excluded columns
    const mainColumns = columns.filter(
      (col) =>
        !horasColumnKeys.includes(col.key) &&
        !excludeColumns.includes(col.title)
    );

    // Build tableHeaders and tableRows for main table
    const tableHeaders = mainColumns.map((col) => col.title);

    const tableRows = payrollData.map((dataRow) => {
      return mainColumns.map((col) => {
        let value = dataRow[col.key];

        // Handle 'name' column to include 'gov_id' if not already included
        if (col.key === 'name' && isCondensed) {
          const govId = dataRow['gov_id'] || '';
          value = `${dataRow['name']}\n${govId}`;
        }

        // Format value
        if (typeof value === 'number') {
          value = parseFloat(value).toFixed(2);
          if (!value.toString().includes('$')) {
            value = '$' + value;
          }
        } else if (value == null || value === '') {
          value = '';
        }

        return value;
      });
    });

    // Calculate totals
    const totals = tableHeaders.map((header, index) => {
      if (
        header !== 'Colaborador' &&
        header !== 'Notas' &&
        header !== 'Cédula'
      ) {
        const total = tableRows
          .reduce((acc, row) => {
            let value = row[index];
            if (typeof value === 'string') {
              value = value.replace(/[^\d.-]/g, ''); // Remove non-numeric characters
            }
            return acc + (parseFloat(value) || 0);
          }, 0)
          .toFixed(2);
        return total === '0.00' ? '$0' : '$' + total;
      }
      return '';
    });

    tableRows.push(totals);

    // Generate the main table
    const tableStyles = {
      head: [tableHeaders],
      theme: 'plain',
      body: tableRows,
      startY: 100,
      styles: {
        fontSize: 10,
        cellPadding: { top: 5, right: 3, bottom: 5, left: 3 },
        overflow: 'linebreak',
        valign: 'middle',
        halign: 'right',
        fillColor: [255, 255, 255],
      },
      headStyles: {
        fillColor: [116, 116, 199],
        textColor: [255, 255, 255],
        fontSize: 8,
        halign: 'center',
      },
      bodyStyles: {
        halign: 'right',
        cellPadding: { top: 12, bottom: 12 },
      },
      columnStyles: {
        [tableHeaders.indexOf('Horas Extra comp.')]: {
          textColor: [135, 62, 35],
          fontStyle: 'bold',
        },
        [tableHeaders.indexOf('Total de Ingresos')]: {
          textColor: [0, 128, 0],
          fontStyle: 'bold',
        },
        [tableHeaders.indexOf('Deduc. totales')]: {
          textColor: [255, 0, 0],
          fontStyle: 'bold',
        },
        [tableHeaders.indexOf('Sal. Neto a Recibir')]: {
          textColor: [65, 130, 239],
          fontStyle: 'bold',
        },
        [tableHeaders.indexOf('Colaborador')]: {
          cellWidth: 'auto',
          halign: 'left',
        },
      },
      margin: { top: 150, left: 20, right: 20 },
    };

    doc.autoTable(tableStyles);

    // Check if we need to add the "horas" table
    if (
      payrollWithHoursBrokenDown &&
      payrollData.some((data) =>
        horasColumnKeys.some((key) => data[key] != null && data[key] !== 0)
      )
    ) {
      // Build the "horas" table
      const horasTableHeaders = horasColumns.map((col) => col.title);
      horasTableHeaders.unshift('Colaborador'); // Add 'Colaborador' column
      horasTableHeaders.push('Total Horas Extra'); // Add 'Total Horas Extra' column

      // Build data for the "horas" table
      const horasTableRows = payrollData.map((data) => {
        const rowData = [];
        // Add 'Colaborador' data
        const govId = data['gov_id'] || '';
        const nameWithGovId = `${data['name']}\n${govId}`;
        rowData.push(nameWithGovId);

        horasColumns.forEach((col) => {
          let value = data[col.key] ?? '';
          if (typeof value === 'number') {
            value = value.toFixed(2);
          }
          // If the column is a 'pago_' column or 'salarioporhora', format as dollars
          if (col.key.startsWith('pago_') || col.key === 'salarioporhora') {
            value = '$' + value;
          }
          rowData.push(value);
        });

        // Calculate 'Total Horas Extra' as the sum of 'pago_' columns
        const totalHorasExtra = horasColumnKeys
          .filter((key) => key.startsWith('pago_'))
          .reduce((sum, key) => sum + (parseFloat(data[key]) || 0), 0);
        const formattedTotalHorasExtra = '$' + totalHorasExtra.toFixed(2);
        rowData.push(formattedTotalHorasExtra);

        return rowData;
      });

      // Calculate totals
      const totalsRow = horasTableHeaders.map((header, index) => {
        if (header === 'Colaborador') {
          return 'Totales';
        } else if (header !== 'Total Horas Extra comp.') {
          const total = horasTableRows.reduce((acc, row) => {
            let value = row[index];
            if (typeof value === 'string') {
              value = value.replace(/[^\d.-]/g, '');
            }
            return acc + (parseFloat(value) || 0);
          }, 0);
          const correspondingCol = horasColumns[index - 1]; // Adjust for 'Colaborador' column
          if (
            (correspondingCol &&
              (correspondingCol.key.startsWith('pago_') ||
                correspondingCol.key === 'salarioporhora')) ||
            header === 'Total Horas Extra'
          ) {
            return '$' + total.toFixed(2);
          }
          return total.toFixed(2);
        } else {
          // Total Horas Extra column
          const total = horasTableRows.reduce((acc, row) => {
            let value = row[row.length - 1];
            if (typeof value === 'string') {
              value = value.replace(/[^\d.-]/g, '');
            }
            return acc + (parseFloat(value) || 0);
          }, 0);
          return '$' + total.toFixed(2);
        }
      });

      horasTableRows.push(totalsRow);

      // Add a new page for the "horas" table
      doc.addPage();
      doc.setFontSize(16);
      doc.setFont('Helvetica', 'bold');
      doc.text('Desglose de Horas Extra', 40, 50);
      // Generate the "horas" table
      doc.autoTable({
        head: [horasTableHeaders],
        body: horasTableRows,
        startY: 80,
        styles: {
          fontSize: 10,
          cellPadding: { top: 5, right: 3, bottom: 5, left: 3 },
          overflow: 'linebreak',
          valign: 'middle',
          halign: 'right',
          fillColor: [255, 255, 255],
        },
        headStyles: {
          fillColor: [116, 116, 199],
          textColor: [255, 255, 255],
          fontSize: 8,
          halign: 'center',
        },
        bodyStyles: {
          halign: 'right',
          cellPadding: { top: 12, bottom: 12 },
        },
        columnStyles: {
          0: { halign: 'left' }, // 'Colaborador' column
          [tableHeaders.indexOf('Total Horas Extra')]: {
            textColor: [135, 62, 35],
            fontStyle: 'bold',
          },
        },
        margin: { top: 50, left: 20, right: 20 },
      });
    }

    if (outputOnlyBlob) {
      const pdfBlob = await doc.output('blob');
      return pdfBlob;
    } else {
      doc.save(file_name);
    }
  };

  const getSlipPDF = async (employee, outputOnlyBlob = false) => {
    const doc = new jsPDF('landscape', 'pt', 'A4');
    doc.setFontSize(18);
    const title = `Comprobante de Planilla: ${formatDateSpanishLong(
      employee.payable_date
    )} ${decimoTercerMesBoolean ? '- Décimo Tercer Mes' : ''}  ${
      vacationBoolean ? '- Vacaciones' : ''
    }`;
    doc.text(title, 40, 70);

    const logo1 = await loadImageBase64(props.logoBase64);
    const logo2 = await loadImage('heroLogoSmall.png');

    const drawLogos = async () => {
      const pageWidth = doc.internal.pageSize.getWidth();
      const logoHeight = 50;
      let xOffset = pageWidth - 30;
      const yOffset = 20;

      if (logo2) {
        const aspectRatio2 = logo2.width / logo2.height;
        const logoWidth2 = logoHeight * aspectRatio2;
        xOffset -= logoWidth2;
        doc.addImage(
          logo2.src,
          'PNG',
          xOffset,
          yOffset,
          logoWidth2,
          logoHeight
        );
      }

      if (logo1) {
        const aspectRatio1 = logo1.width / logo1.height;
        const logoWidth1 = logoHeight * aspectRatio1;
        xOffset -= logoWidth1 + 10;
        doc.addImage(
          logo1.src,
          'PNG',
          xOffset,
          yOffset,
          logoWidth1,
          logoHeight
        );
      }

      // Add employee name in bold
      doc.setFontSize(14);
      doc.setFont('Helvetica', 'bold');
      doc.text(`${employee.name}`, 40, 110);

      // Add employee government ID
      doc.setFont('Helvetica', 'normal');
      doc.text(`${employee.gov_id || ''}`, 40, 130);

      doc.setFontSize(12);
      const notesY = 160;
      doc.text(`NOTAS: ${employee.notes || ''}`, 40, notesY);

      const startY = 200;

      const tableWidth = (pageWidth - 80) / 3 - 20;
      const tableMargin = 10;
      const symbolOffset = 15;

      const formatCurrency = (amount) => {
        return `$${parseFloat(amount).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
      };

      // Determine if payroll includes hours breakdown
      const payrollWithHoursBrokenDown =
        employee.horas_extradiurnas > 0 ||
        employee.horas_extranocturnas > 0 ||
        employee.horas_domingos > 0 ||
        employee.horas_dialibre > 0 ||
        employee.horas_recargo > 0;

      // Define ingresos items
      const ingresosItems = [
        {
          label: 'Salario Regular',
          horas: '', // No hours associated with regular salary
          monto: employee.biweekly_salary,
          showHoras: false, // Always show, no horas column
        },
        {
          label: 'Prima de Producción',
          horas: '', // No hours associated
          monto: employee.benefit_production,
          showHoras: false,
        },
        {
          label: 'Combustible',
          horas: '', // No hours associated
          monto: employee.benefit_fuel,
          showHoras: false,
        },
        {
          label: 'Gastos de Representación',
          horas: '', // No hours associated
          monto: employee.benefit_representation_expenses,
          showHoras: false,
        },
        {
          label: 'Tiempo Extra',
          horas: '', // No hours associated
          monto: employee.overtime,
          showHoras: false,
        },
        {
          label: 'Pago Horas Diurnas',
          horas: employee.horas_extradiurnas,
          monto: employee.pago_extradiurnas,
          showHoras:
            payrollWithHoursBrokenDown && employee.horas_extradiurnas > 0,
        },
        {
          label: 'Pago Horas Nocturnas',
          horas: employee.horas_extranocturnas,
          monto: employee.pago_extranocturnas,
          showHoras:
            payrollWithHoursBrokenDown && employee.horas_extranocturnas > 0,
        },
        {
          label: 'Pago Horas Domingos',
          horas: employee.horas_domingos,
          monto: employee.pago_domingos,
          showHoras: payrollWithHoursBrokenDown && employee.horas_domingos > 0,
        },
        {
          label: 'Pago Horas Día Libre',
          horas: employee.horas_dialibre,
          monto: employee.pago_dialibre,
          showHoras: payrollWithHoursBrokenDown && employee.horas_dialibre > 0,
        },
        {
          label: 'Pago Horas Recargo',
          horas: employee.horas_recargo,
          monto: employee.pago_recargo,
          showHoras: payrollWithHoursBrokenDown && employee.horas_recargo > 0,
        },
      ];

      // Construct table headers and body for ingresos
      const ingresosHead = payrollWithHoursBrokenDown
        ? ['Ingresos', 'Horas', 'Monto']
        : ['Ingresos', 'Monto'];

      const ingresosBody = ingresosItems
        .filter((item) => item.monto > 0)
        .map((item) => {
          if (payrollWithHoursBrokenDown && item.showHoras) {
            return [
              item.label,
              item.horas.toString(),
              formatCurrency(item.monto),
            ];
          } else {
            if (payrollWithHoursBrokenDown) {
              return [item.label, '', formatCurrency(item.monto)];
            } else {
              return [item.label, formatCurrency(item.monto)];
            }
          }
        });

      // Add Total de Ingresos row
      if (payrollWithHoursBrokenDown) {
        ingresosBody.push([
          'Total de Ingresos',
          '',
          formatCurrency(employee.payroll_gross),
        ]);
      } else {
        ingresosBody.push([
          'Total de Ingresos',
          formatCurrency(employee.payroll_gross),
        ]);
      }

      // Function to draw tables with borders
      const drawTableWithBorder = (
        x,
        y,
        head,
        body,
        headStyles,
        symbol,
        lastRowColor
      ) => {
        const numColumns = head.length;

        const columnStyles = {};
        // Align the last column to the right (assuming it contains numbers)
        columnStyles[numColumns - 1] = { halign: 'right' };
        // If there is a 'Horas' column, align it to center
        if (payrollWithHoursBrokenDown && numColumns === 3) {
          columnStyles[1] = { halign: 'center' };
        }

        doc.autoTable({
          head: [head],
          theme: 'plain',
          body: body,
          startY: y + tableMargin,
          tableWidth: tableWidth - tableMargin * 2,
          margin: { left: x + tableMargin },
          styles: {
            cellPadding: 3,
            fontSize: 10,
            fillColor: [255, 255, 255],
            // halign: 'right', // Align all table content to the right
          },
          headStyles: {
            ...headStyles,
            halign: 'left',
          },
          bodyStyles: { fillColor: [255, 255, 255] },
          columnStyles: columnStyles,
          didParseCell: function (data) {
            if (data.section === 'body' && data.row.index === body.length - 1) {
              data.cell.styles.textColor = lastRowColor;
              data.cell.styles.fontStyle = 'bold';

              // Align the first column (labels) to the left
              if (data.column.index === 0) {
                data.cell.styles.halign = 'left';
              } else {
                // Align other columns (amounts) to the right
                data.cell.styles.halign = 'right';
              }
            }
          },
        });

        const tableHeight = doc.lastAutoTable.finalY - y;
        doc.setLineWidth(1.2);
        doc.roundedRect(x, y, tableWidth, tableHeight + tableMargin * 2, 5, 5);

        if (symbol) {
          const symbolX = x + tableWidth + symbolOffset;
          const symbolY = y + (tableHeight + tableMargin * 2) / 2;
          doc.text(symbol, symbolX, symbolY, {
            align: 'center',
            baseline: 'middle',
          });
        }
      };

      drawTableWithBorder(
        40,
        startY,
        ingresosHead,
        ingresosBody,
        { fillColor: [55, 126, 34], textColor: [255, 255, 255] },
        '-',
        [55, 126, 34]
      );

      drawTableWithBorder(
        40 + tableWidth + symbolOffset * 2,
        startY,
        ['Deducciones', 'Monto'],
        [
          ['Seguro Social 9.75%', formatCurrency(employee.deduction_ss)],
          ['Seguro Educativo 1.25%', formatCurrency(employee.deduction_se)],
          ['ISR', formatCurrency(employee.deduction_isr)],
          ...(employee.deduction_attendance > 0
            ? [['Ausencias', formatCurrency(employee.deduction_attendance)]]
            : []),
          ...(employee.deduction_direct_discount > 0
            ? [
                [
                  'Descuento Directo',
                  formatCurrency(employee.deduction_direct_discount),
                ],
              ]
            : []),
          ...(employee.advance_deductions > 0
            ? [
                [
                  'Descuento de Adelantos',
                  formatCurrency(employee.advance_deductions),
                ],
              ]
            : []),
          ['Total de Deducciones', formatCurrency(employee.deduction_total)],
        ],
        { fillColor: [231, 76, 60], textColor: [255, 255, 255] },
        '=',
        [231, 76, 60]
      );

      drawTableWithBorder(
        40 + tableWidth * 2 + symbolOffset * 4,
        startY,
        ['Salario Neto a Recibir', 'Monto'],
        [['', formatCurrency(employee.payroll_net)]],
        { fillColor: [81, 128, 232], textColor: [255, 255, 255] },
        null,
        [81, 128, 232]
      );

      const firmaY = doc.internal.pageSize.getHeight() - 160;
      doc.setFontSize(12);
      doc.text(
        'Firma Recibido: __________________________________',
        40,
        firmaY
      );

      if (outputOnlyBlob) {
        const pdfBlob = await doc.output('blob'); // Await the Promise here
        return pdfBlob;
      } else {
        const fileName = `${employee.payable_date}_comprobante_${employee.name}.pdf`;
        doc.save(fileName);
      }
    };

    try {
      if (outputOnlyBlob) {
        const pdfBlob = await drawLogos();
        return pdfBlob;
      } else {
        await drawLogos();
      }
    } catch (error) {
      console.error('Failed to generate PDF:', error);
      throw error;
    }
  };

  const exportZoho = (approved_payroll_id) => {
    const data = { payrollId: approved_payroll_id };

    axios({
      method: 'post',
      url: props.API_domain + 'getCSVwithPayrollDetails',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        const csvContent = response.data;
        const csvBlob = new Blob(['\ufeff', csvContent], {
          type: 'text/csv;charset=utf-8;',
        });
        const downloadUrl = window.URL.createObjectURL(csvBlob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = downloadUrl;
        a.download = `${approved_payroll_id}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const exportSipe = (approved_payroll_id) => {
    const data = { payrollId: approved_payroll_id };

    axios({
      method: 'post',
      url: props.API_domain + 'getSIPEUploader',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        const csvContent = response.data;
        const csvBlob = new Blob(['\ufeff', csvContent], {
          type: 'text/csv;charset=utf-8;',
        });
        const downloadUrl = window.URL.createObjectURL(csvBlob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = downloadUrl;
        a.download = `${approved_payroll_id}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const sendEmails = async () => {
    //if (emailSentRef.current) return;
    //emailSentRef.current = true;

    if (!payrollDatesForSelect.length || !selectedDate || !payrollData.length) {
      console.error('Required data not available');
      return;
    }
    const get_accounting_client_data_response = await axios.get(
      `${props.API_domain}getDataForAccountingClient/${props.selectedAccountingClientId}`,
      {
        auth: {
          username: auth.email,
          password: auth.token,
        },
      }
    );

    const { accounting_client_name, emails } =
      get_accounting_client_data_response.data;

    const payrollPDF = await exportPDF(
      payrollWithHoursBrokenDown,
      isCondensed,
      payrollData[0].payable_date + '_planilla.pdf',
      true // Output only the PDF blob
    );

    // Generate all slip PDFs
    const slipPDFsPromises = payrollData.map((employee) =>
      getSlipPDF(employee, true)
    );
    const slipPDFs = await Promise.all(slipPDFsPromises);

    setEmailData({
      to: emails.join(','),
      subject: `Planilla del ${payrollData[0].payable_date} - ${accounting_client_name}`,
      body: 'Adjunto podrán encontrar la planilla y sus comprobantes.',
      attachments: [
        {
          name: `${payrollData[0].payable_date}_planilla.pdf`,
          content: payrollPDF,
        },
        ...slipPDFs.map((pdf, index) => ({
          name: `${payrollData[index].payable_date}_comprobante_${payrollData[index].name}.pdf`,
          content: pdf,
        })),
      ],
    });
    // Show the modal for email review
    setIsEmailEditModalVisible(true);
  };

  const handleSend = (data) => {
    // Function to handle sending the email
    // use data.to, data.subject, data.body, data.attachments to send the email
    console.log('Email Data to send:', data);
    setIsModalVisible(false);

    // Proceed with sending the email
  };

  useEffect(() => {
    if (
      props.automaticallySendEmailBoolean &&
      payrollDatesForSelect.length &&
      payrollData.length
    ) {
      sendEmails();
    } else if (props.sendApprovedPayroll) {
      if (payrollDatesForSelect.length && payrollData.length) {
        sendEmails();
        props.setSendApprovedPayroll(false);
      }
    }
  }, [
    props.automaticallySendEmailBoolean,
    payrollDatesForSelect,
    payrollData,
    props.sendApprovedPayroll,
  ]);

  useEffect(() => {
    const fetchPayrollDatesForSelect = async () => {
      try {
        const response = await axios.post(
          `${props.API_domain}getOldPayrollDates`,
          {
            selectedAccountingClientId: props.selectedAccountingClientId,
          },
          { auth: { username: auth.email, password: auth.token } }
        );
        if (response.data && Array.isArray(response.data)) {
          setPayrollDatesForSelect(response.data);
          if (!selectedDate) {
            setSelectedDate(response.data[0]);
          }
        }
      } catch (error) {
        console.error('Error fetching payroll dates:', error);
        message.error('Failed to load payroll dates.');
      }
    };

    fetchPayrollDatesForSelect();
  }, []);

  useEffect(() => {
    setDecimoTercerMesBoolean(false);
    setVacationBoolean(false);
    setRenderAdvancePaymentOnly(false);
    const fetchPayrollData = async () => {
      if (!selectedDate) return;
      try {
        const response = await axios.post(
          `${props.API_domain}getPayroll`,
          {
            accountingClientsId: props.selectedAccountingClientId,
            approvedPayrollId: selectedDate.approved_payroll_id,
          },
          { auth: { username: auth.email, password: auth.token } }
        );
        if (response.data && Array.isArray(response.data.payroll)) {
          if (response.data.payroll[0].comments === 'DECIMO') {
            setDecimoTercerMesBoolean(true);
          }
          if (response.data.payroll[0].comments === 'VACACIONES') {
            setVacationBoolean(true);
          }
          response.data.payroll.forEach((record, index) => {
            record.index = index + 1;
            if (record.pago_total_extras > 0) {
              setPayrollWithHoursBrokenDown(true);
            }
          });
          setPayrollData(response.data.payroll);
          // check if payroll is simply an advance_payment
          if (
            response.data.payroll.length === 1 &&
            response.data.payroll[0].comments === 'ADELANTO'
          ) {
            setRenderAdvancePaymentOnly(true);
          }
          if (response.data.payroll[0].comments === 'VACACIONES') {
          }
        }
      } catch (error) {
        console.error('Error fetching payroll data:', error);
        message.error('Failed to load payroll data.');
      }
    };

    fetchPayrollData();
  }, [selectedDate]);

  const handleDateChange = (value) => {
    const date = payrollDatesForSelect.find(
      (d) => d.approved_payroll_id === value
    );
    setSelectedDate(date);
    setPayrollWithHoursBrokenDown(false);
  };

  const menu = (
    <Menu>
      <Menu.Item key='1'>
        <Button
          type='link'
          onClick={() =>
            exportPDF(
              payrollWithHoursBrokenDown,
              isCondensed,
              payrollData[0].payable_date + '_planilla.pdf',
              false
            )
          }
          disabled={!selectedDate}
        >
          Exportar a <b> PDF</b>
        </Button>
      </Menu.Item>
      <Menu.Item key='2'>
        <Button
          type='link'
          onClick={() => exportZoho(selectedDate.approved_payroll_id)}
          disabled={!selectedDate}
        >
          Exportar a <b> ZOHO</b>
        </Button>
      </Menu.Item>
      <Menu.Item key='3'>
        <Button
          type='link'
          onClick={() => exportSipe(selectedDate.approved_payroll_id)}
          disabled={!selectedDate}
        >
          Exportar a <b> SIPE</b>
        </Button>
      </Menu.Item>
      <Menu.Item key='4'>
        <Button type='link' onClick={sendEmails} disabled={!selectedDate}>
          Mandar <b> Correo</b>
        </Button>
      </Menu.Item>
      <Menu.Item key='5'>
        <Button
          type='link'
          loading={deleting}
          onClick={() => {
            setDeleting(true);
            axios({
              method: 'post',
              url: props.API_domain + 'deleteApprovedPayroll',
              data: {
                approved_payroll_id: selectedDate.approved_payroll_id,
              },
              auth: {
                username: auth.email,
                password: auth.token,
              },
            })
              .then((response) => {
                setDeleting(false);
                message.success('Planilla borrada exitosamente');
                setSelectedDate(null);
                props.onCancel();
              })
              .catch((error) => {
                console.error('Error:', error);
                message.error('Failed to delete payroll');
              });
          }}
          disabled={!selectedDate}
          style={{ color: 'red', fontWeight: 'bold' }}
        >
          Borrar Planilla
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div id='SelectDiv' style={{ flex: '0 1 auto' }}>
          <Select
            style={{ width: 320, marginBottom: 20 }}
            placeholder='Escoge una planilla'
            onChange={handleDateChange}
            value={selectedDate ? selectedDate.approved_payroll_id : undefined}
          >
            {payrollDatesForSelect
              .sort(
                (a, b) => new Date(b.payable_date) - new Date(a.payable_date)
              )
              .map((date) => (
                <Option
                  key={date.approved_payroll_id}
                  value={date.approved_payroll_id}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      {date.payable_date}
                      {date.comments ? ` - ${date.comments}` : ''}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <b>
                        <FormattedUSD total={date.total_payroll_net} />
                      </b>
                      <Tooltip
                        title={
                          <ol style={{ marginLeft: 0, paddingLeft: 16 }}>
                            {date.employee_names
                              .split(',')
                              .map((name, index) => (
                                <li key={index}>{name.trim()}</li>
                              ))}
                          </ol>
                        }
                        placement='topLeft'
                      >
                        <span style={{ color: 'gray', marginLeft: 10 }}>
                          {date.employee_names.split(',').length}
                          <UserOutlined style={{ marginLeft: 4 }} />
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                </Option>
              ))}
          </Select>
        </div>
        <div>
          <h2 style={{ paddingLeft: 20, fontSize: 30 }}>
            {selectedDate ? (
              <>
                {renderAdvancePaymentOnly ? 'Adelanto' : 'Planilla'} del{' '}
                <b>{formatDateSpanishLong(selectedDate.payable_date)}</b>
                {decimoTercerMesBoolean ? ' - Décimo Tercer Mes' : ''}
                {vacationBoolean ? ' - Vacaciones' : ''}
              </>
            ) : (
              <>Planilla</>
            )}
          </h2>
        </div>
        {!renderAdvancePaymentOnly ? (
          <div id='ActionsDiv' style={{ flex: '1 1 auto', textAlign: 'right' }}>
            <span style={{ marginLeft: 8, marginRight: 8 }}>Vista simple:</span>
            <Switch checked={isCondensed} onChange={toggleView} />
            <Dropdown
              overlay={menu}
              trigger={['click']}
              placement='bottomRight'
            >
              <Button type='primary' style={{ marginLeft: 10 }}>
                Acciones <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        ) : (
          <div id='ActionsDiv' style={{ flex: '1 1 auto', textAlign: 'right' }}>
            {/* intentionally empty to not screw up rendering */}
          </div>
        )}
      </div>

      {renderAdvancePaymentOnly ? (
        <div
          style={{
            height: '320px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <h2 style={{ textAlign: 'center' }}>
            Adelanto de{' '}
            <span style={{ fontWeight: 'bold' }}>
              <FormattedUSD total={payrollData[0].advance_payments} />
            </span>{' '}
            a <span style={{ fontWeight: 'bold' }}>{payrollData[0].name}</span>
          </h2>
        </div>
      ) : (
        <div
          style={{
            overflowX: 'auto', // Allows horizontal scrolling
            marginTop: 20, // Space between fixed section and table
          }}
        >
          <ExistingPayrollTableTable
            ref={tableRef} // this is for exportPDF to work
            payrollData={payrollData}
            isCondensed={isCondensed}
            handleRowClick={handleRowClick}
            isModalVisible={isModalVisible}
            handleModalClose={handleModalClose}
            selectedEmployee={selectedEmployee}
            getSlipPDF={getSlipPDF}
            decimoTercerMesBoolean={decimoTercerMesBoolean}
            logoSrc={props.logoSrc}
            payrollWithHoursBrokenDown={payrollWithHoursBrokenDown}
          />
          <EmailReviewModal
            visible={isEmailEditModalVisible}
            onCancel={() => setIsEmailEditModalVisible(false)}
            onSend={handleSend}
            initialValues={emailData}
            API_domain={props.API_domain}
          />
        </div>
      )}
    </>
  );
}

export { ExistingPayrollTableContainer };
