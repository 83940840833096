import React from 'react';
import { Col, Row } from 'antd';
import { FormattedUSD } from '../FormattedUSD.js';
import { formatDateSpanishLong } from '../../utils';

function PayrollSlip(props) {
  const { logoSrc, selectedEmployee, payrollWithHoursBrokenDown, id } = props;
  console.log('PayrollSlip props:', props);
  // Destructure the employee data for easier access
  const {
    name,
    gov_id,
    payable_date,
    biweekly_salary,
    benefit_production,
    benefit_fuel,
    benefit_representation_expenses,
    overtime,
    payroll_gross,
    payroll_net,
    // Hours and their payments
    horas_extradiurnas,
    pago_extradiurnas,
    horas_extranocturnas,
    pago_extranocturnas,
    horas_dialibre,
    pago_dialibre,
    horas_domingos,
    pago_domingos,
    horas_recargo,
    pago_recargo,
  } = selectedEmployee;

  // Define the ingresos items
  const ingresosItems = [
    {
      label: 'Salario Regular',
      horas: '', // No hours associated with regular salary
      monto: biweekly_salary,
      showHoras: false, // Always show, no horas column
    },
    {
      label: 'Prima de Producción',
      horas: '', // No hours associated
      monto: benefit_production,
      showHoras: false,
    },
    {
      label: 'Combustible',
      horas: '', // No hours associated
      monto: benefit_fuel,
      showHoras: false,
    },
    {
      label: 'Gastos de Representación',
      horas: '', // No hours associated
      monto: benefit_representation_expenses,
      showHoras: false,
    },
    {
      label: 'Tiempo Extra',
      horas: '', // No hours associated
      monto: overtime,
      showHoras: false,
    },
    {
      label: 'Pago Horas Diurnas',
      horas: horas_extradiurnas,
      monto: pago_extradiurnas,
      showHoras: payrollWithHoursBrokenDown && horas_extradiurnas > 0,
    },
    {
      label: 'Pago Horas Nocturnas',
      horas: horas_extranocturnas,
      monto: pago_extranocturnas,
      showHoras: payrollWithHoursBrokenDown && horas_extranocturnas > 0,
    },
    {
      label: 'Pago Horas Domingos',
      horas: horas_domingos,
      monto: pago_domingos,
      showHoras: payrollWithHoursBrokenDown && horas_domingos > 0,
    },
    {
      label: 'Pago Horas Día Libre',
      horas: horas_dialibre,
      monto: pago_dialibre,
      showHoras: payrollWithHoursBrokenDown && horas_dialibre > 0,
    },
    {
      label: 'Pago Horas Recardo',
      horas: horas_recargo,
      monto: pago_recargo,
      showHoras: payrollWithHoursBrokenDown && horas_dialibre > 0,
    },
  ];

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {selectedEmployee ? (
        <div id={id} style={{ width: '100%' }}>
          {/* Header Section */}
          <Row>
            <Col span={12}>
              <h2 style={{ marginBottom: 10 }}>
                Comprobante de Planilla: {formatDateSpanishLong(payable_date)}{' '}
              </h2>
              <div style={{ fontSize: 18, marginTop: 20 }}>
                <b>{name}</b>
              </div>
              <div style={{ marginBottom: 10 }}>{gov_id}</div>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <img src={logoSrc} style={{ maxHeight: 70 }} alt='Client Logo' />
              <img
                src='heroLogoTransparent.png'
                style={{ maxHeight: 70, marginLeft: 10 }}
                alt='Hero Logo'
              />
            </Col>
          </Row>

          {/* Comments and Notes */}
          <Row>
            {selectedEmployee.comments && (
              <div style={{ marginBottom: 16 }}>
                <b>{selectedEmployee.comments}</b>
              </div>
            )}
          </Row>
          <Row>
            {selectedEmployee.notes && (
              <div style={{ marginBottom: 16 }}>
                Notas: <b>{selectedEmployee.notes}</b>
              </div>
            )}
          </Row>

          {/* Ingresos, Deducciones, and Neto */}
          <Row>
            {/* Ingresos Section */}
            <Col span={8}>
              <div
                style={{
                  padding: 20,
                  margin: 10,
                  border: '2px solid',
                  borderRadius: 6,
                }}
              >
                <h3>Ingresos</h3>
                <Row>
                  {/* Adjust columns to accommodate the new "Horas" column */}
                  <Col span={12} style={{ fontWeight: 700 }}>
                    {/* Labels */}
                    <span>
                      <br></br>
                    </span>
                    <div>Salario Regular</div>
                    {ingresosItems.map(
                      (item, index) =>
                        item.showHoras && <div key={index}>{item.label}</div>
                    )}
                    {/* Always show Salario Regular */}
                  </Col>
                  {/* "Horas" Column */}
                  {payrollWithHoursBrokenDown ? (
                    <Col span={6} style={{ textAlign: 'right' }}>
                      <span>Horas</span>
                      {/* Blank for Salario Regular */}
                      <br></br>
                      <br></br>
                      {ingresosItems.map(
                        (item, index) =>
                          item.showHoras && <div key={index}>{item.horas}</div>
                      )}
                    </Col>
                  ) : null}
                  {/* "Monto" Column */}
                  <Col
                    span={payrollWithHoursBrokenDown ? 6 : 12}
                    style={{ textAlign: 'right' }}
                  >
                    <span>
                      <br></br>
                    </span>
                    {/* Salario Regular Amount */}
                    <div>
                      <FormattedUSD total={biweekly_salary} />
                    </div>
                    {ingresosItems.map(
                      (item, index) =>
                        item.showHoras && (
                          <div key={index}>
                            <FormattedUSD total={item.monto} />
                          </div>
                        )
                    )}
                  </Col>
                </Row>

                {/* Total de Ingresos */}
                <Row
                  style={{
                    borderTop: 'solid 1px black',
                    marginTop: 10,
                    color: 'green',
                    fontWeight: 700,
                  }}
                >
                  <Col span={payrollWithHoursBrokenDown ? 16 : 24}>
                    <div>Total de Ingresos</div>
                  </Col>
                  <Col
                    span={payrollWithHoursBrokenDown ? 8 : 24}
                    style={{ textAlign: 'right' }}
                  >
                    <FormattedUSD total={payroll_gross} />
                  </Col>
                </Row>
              </div>
            </Col>

            {/* Separator */}
            <Col span={1}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  fontSize: 24,
                }}
              >
                -
              </div>
            </Col>

            {/* Deducciones Section */}
            <Col span={8}>
              <div
                style={{
                  padding: 20,
                  margin: 10,
                  border: '2px solid',
                  borderRadius: 6,
                }}
              >
                <h3>Deducciones</h3>
                <Row>
                  <Col span={16} style={{ fontWeight: 700 }}>
                    <div>Seguro Social 9.75%</div>
                    <div>Seguro Educativo 1.25%</div>
                    <div>ISR</div>
                    {selectedEmployee.deduction_attendance > 0 && (
                      <div>Ausencias</div>
                    )}
                    {selectedEmployee.deduction_direct_discount > 0 && (
                      <div>Descuento Directo</div>
                    )}
                    {selectedEmployee.advance_deductions > 0 && (
                      <div>Descuento de Adelantos</div>
                    )}
                  </Col>
                  <Col span={8} style={{ textAlign: 'right' }}>
                    <div>
                      <FormattedUSD total={selectedEmployee.deduction_ss} />
                    </div>
                    <div>
                      <FormattedUSD total={selectedEmployee.deduction_se} />
                    </div>
                    <div>
                      <FormattedUSD total={selectedEmployee.deduction_isr} />
                    </div>
                    {selectedEmployee.deduction_attendance > 0 && (
                      <div>
                        <FormattedUSD
                          total={selectedEmployee.deduction_attendance}
                        />
                      </div>
                    )}
                    {selectedEmployee.deduction_direct_discount > 0 && (
                      <div>
                        <FormattedUSD
                          total={selectedEmployee.deduction_direct_discount}
                        />
                      </div>
                    )}
                    {selectedEmployee.advance_deductions > 0 && (
                      <div>
                        <FormattedUSD
                          total={selectedEmployee.advance_deductions}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
                <Row
                  style={{
                    borderTop: 'solid 1px black',
                    marginTop: 10,
                    color: 'red',
                    fontWeight: 700,
                  }}
                >
                  <Col span={16}>
                    <div>Total de Deducciones</div>
                  </Col>
                  <Col span={8} style={{ textAlign: 'right' }}>
                    <FormattedUSD total={selectedEmployee.deduction_total} />
                  </Col>
                </Row>
              </div>
            </Col>

            {/* Separator */}
            <Col span={1}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  fontSize: 24,
                }}
              >
                =
              </div>
            </Col>

            {/* Salario Neto a Recibir Section */}
            <Col span={6}>
              <div
                style={{
                  padding: 20,
                  margin: 10,
                  border: '2px solid',
                  borderRadius: 6,
                }}
              >
                <h3>Salario Neto a Recibir</h3>
                <div
                  style={{
                    color: 'rgb(65,130,239)',
                    fontWeight: 700,
                    fontSize: 20,
                    display: 'flex',
                    alignItems: 'center',
                    height: '50%',
                  }}
                >
                  <FormattedUSD total={payroll_net} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ) : null}
    </div>
  );
}

export { PayrollSlip };
