import { Button, Col, Form, Input, Row, Select } from 'antd';
import axios from 'axios';
import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { LoginFormFeedback } from './LoginFormFeedback';
import { authContext } from '../ProvideAuth.js';
import { useHistory } from 'react-router-dom';

function LoginView(props) {
  const auth = useContext(authContext);
  const [loginSent, setLoginSent] = useState(false);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [validateStatus, setValidateStatus] = useState(null);
  const [helpMessage, setHelpMessage] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [loginChoice, setLoginChoice] = useState(null);

  const [form] = Form.useForm();
  const { Option } = Select;

  const history = useHistory();

  const onFinish = (values) => {
    const data = { email: values.email.trim() };
    setEmail(values.email);
    setSubmitted(true);
    axios
      .post(`${props.API_domain}sendEmail`, data)
      .then((response) => {
        setLoginSent(true);
        console.log(response);
      })
      .catch((error) => {
        setSubmitted(false);
        setValidateStatus('error');
        const errorMessage =
          error.message === 'Network Error'
            ? 'Hay un problema con tu conexión'
            : 'Este correo no está en nuestra base de datos';
        setHelpMessage(errorMessage);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  function generateOTC(phone_no) {
    setSubmitted(true);
    setPhone(phone_no);
    axios
      .post(`${props.API_domain}generateOTC`, { phone_no })
      .then((response) => {
        setLoginSent(true);
        console.log(response);
      })
      .catch((error) => {
        setSubmitted(false);
        setValidateStatus('error');
        const errorMessage = 'Número no está en nuestra base de datos';
        setHelpMessage(errorMessage);
      });
  }

  useEffect(() => {
    if (window.location.hostname === 'herofacturas.com') {
      window.location.replace(`https://www.herofacturas.com/login`);
    } else if (auth.email) {
      history.push('/expenses');
    }
  }, [auth.email, history]);

  return (
    <PageContainer>
      <Logo src='./HERO_LOGO-03.png' alt='Hero' />
      <LoginBox>
        {submitted ? (
          <>
            <LoginFormFeedback
              API_domain={props.API_domain}
              loginChoice={loginChoice}
              email={email}
              phone_no={phone}
              loginSent={loginSent}
            />
          </>
        ) : loginChoice == null ? (
          <div>
            <AccedaDiv>Acceda a su cuenta de Hero</AccedaDiv>
            <ChoiceContainer>
              <ChoiceButton
                type='primary'
                onClick={() => setLoginChoice('whatsapp')}
              >
                WhatsApp
              </ChoiceButton>
              <ChoiceButton
                type='primary'
                onClick={() => setLoginChoice('email')}
              >
                Email
              </ChoiceButton>
            </ChoiceContainer>

            <FooterText>
              ¿No tienes una cuenta todavía?{' '}
              <a
                href='https://www.herofacturas.com'
                target='_blank'
                rel='noreferrer'
              >
                Entérate
              </a>
            </FooterText>
          </div>
        ) : (
          <>
            <div>
              <AccedaDiv>Acceda a su cuenta de Hero</AccedaDiv>
              <Form
                name='loginForm'
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout='vertical'
                form={form}
              >
                {loginChoice === 'email' ? (
                  <>
                    <Form.Item
                      label='Email'
                      name='email'
                      validateStatus={validateStatus}
                      help={helpMessage}
                      initialValue={email}
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingresa tu correo electrónico',
                        },
                        {
                          type: 'email',
                          message: 'El correo no es válido',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item>
                      <LoginButton type='primary' htmlType='submit' block>
                        Ingresar
                      </LoginButton>
                    </Form.Item>
                  </>
                ) : (
                  <>
                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name='countryCode'
                          label='País'
                          initialValue='507'
                          rules={[
                            {
                              required: true,
                              message: 'Por favor selecciona un país',
                            },
                          ]}
                        >
                          <Select>
                            {/* Country options */}
                            <Option value='507'>
                              <span role='img' aria-label='Panamá'>
                                🇵🇦
                              </span>{' '}
                              +507
                            </Option>
                            {/* Add more options as needed */}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={16}>
                        <Form.Item
                          name='whatsappNumber'
                          label='WhatsApp (sin guiones)'
                          validateStatus={validateStatus}
                          help={helpMessage}
                          rules={[
                            {
                              required: true,
                              message:
                                'Por favor ingresa tu número de WhatsApp',
                            },
                            {
                              pattern: /^[0-9]+$/,
                              message: 'Solo números, sin espacios ni guiones',
                            },
                          ]}
                        >
                          <Input placeholder='66123456' />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item>
                      <LoginButton
                        htmlType='button'
                        onClick={() => {
                          generateOTC(
                            form.getFieldValue('countryCode') +
                              form.getFieldValue('whatsappNumber')
                          );
                        }}
                        style={{
                          backgroundColor: '#25D366',
                          borderColor: '#25D366',
                          color: 'white',
                          fontWeight: 'bold',
                        }}
                        block
                      >
                        Ingresar
                      </LoginButton>
                    </Form.Item>
                  </>
                )}
              </Form>
            </div>

            <FooterText>
              ¿No tienes una cuenta todavía?{' '}
              <a
                href='https://www.herofacturas.com'
                target='_blank'
                rel='noreferrer'
              >
                Entérate
              </a>
            </FooterText>
            <ChangeLoginMethod
              onClick={() => {
                setLoginChoice(null);
                setValidateStatus(null);
                setHelpMessage(null);
              }}
            >
              Cambiar forma de login
            </ChangeLoginMethod>
          </>
        )}
      </LoginBox>
    </PageContainer>
  );
}

// Styled Components
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
`;

const LoginBox = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 30px 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.img`
  width: 200px;
  margin-bottom: 20px;
`;

const AccedaDiv = styled.div`
  font-weight: bold;
  text-align: center;
  margin-top: 4px;
  margin-bottom: 20px;
  color: rgb(50, 50, 50);
`;

const LoginButton = styled(Button)`
  margin-top: 3px;
`;

const ChoiceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const ChoiceButton = styled(Button)`
  height: 105px;
  width: 48%;
  font-weight: 500;
  border-radius: 8px;
  font-size: 18px;
`;

const FooterText = styled.div`
  margin-bottom: 10px;
  text-align: center;
`;

const ChangeLoginMethod = styled.div`
  cursor: pointer;
  color: #1890ff;
  text-align: center;
`;

export { LoginView };
