// AccountingAccountSelect.js

import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd';
import axios from 'axios';

function AccountingAccountSelect({
  value,
  onChange,
  clientId,
  API_domain,
  auth,
  ...restProps
}) {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch accounts for the client
  useEffect(() => {
    const fetchAccounts = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${API_domain}getClientAccounts`,
          {
            client_id: clientId,
          },
          {
            auth: {
              username: auth.email,
              password: auth.token,
            },
          }
        );
        setAccounts(response.data.accounts);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching accounts:', error);
        message.error('Error al obtener las cuentas');
        setLoading(false);
      }
    };
    if(!restProps.accounts){
      fetchAccounts();
    }
    else{
      setAccounts(restProps.accounts);
    }
  }, [API_domain, clientId, auth]);

  return (
    <Select
      showSearch
      value={value}
      loading={loading}
      style={{ width: '100%' }}
      onChange={onChange}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      {...restProps} // Spread the rest of the props
    >
      {accounts.map((account) => (
        <Select.Option key={account.account_id} value={account.account_id}>
          {account.zoho_account_name}
        </Select.Option>
      ))}
    </Select>
  );
}

export default AccountingAccountSelect;
