import {
  Button,
  Col,
  Dropdown,
  Menu,
  message,
  Modal,
  Popconfirm,
  Row,
  Typography,
} from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import React, { useEffect, useState, useContext } from 'react';
import { ReportedExpenseModal } from './ReportedExpenseModal';
import { ExpenseModalMobile } from './ExpenseModalMobile';
import { FinishedReportTable } from './FinishedReportTable.js';
import { ReportActionButton } from './ReportActionButton';
import { ReportDownloaderConcretex } from './ReportDownloaderConcretex';
import { ReportDownloaderCustom } from './ReportDownloaderCustom';
import { ReportDownloaderGeneral } from './ReportDownloaderGeneral';
import { ReportDownloaderGrupoResidencial01 } from './ReportDownloaderGrupoResidencial01';
import { ReportDownloaderGrupoResidencial02 } from './ReportDownloaderGrupoResidencial02';
import { ReportDownloaderGrupoResidencialCards } from './ReportDownloaderGrupoResidencialCards';
import { ReportDownloaderAcelmar } from './ReportDownloaderAcelmar';
import { ReportDownloaderRiga } from './ReportDownloaderRiga';
import { ReportDownloaderBarrioPizza } from './ReportDownloaderBarrioPizza';
import { ReportDownloaderVertical } from './ReportDownloaderVertical';
import { ReportDownloaderAsyr } from './ReportDownloaderAsyr';
import { ReportDownloaderImages } from './ReportDownloaderImages';
import { ReportDownloaderImagesPDF } from './ReportDownloaderImagesPDF';
import { ReportDownloaderInforme43 } from './ReportDownloaderInforme43';
import { ReportDownloaderPeachtree } from './ReportDownloaderPeachtree';
import { ReportDownloaderQuickbooks } from './ReportDownloaderQuickbooks';
import { ReportDownloaderZohoExpenses } from './ReportDownloaderZohoExpenses';
import { ReportDownloaderZohoExpensesThorough } from './ReportDownloaderZohoExpensesThorough';
import { ReportDownloaderTwoOceans } from './ReportDownloaderTwoOceans';
import { ReportDownloaderVaqueras } from './ReportDownloaderVaqueras';
import { ReportDownloaderVaquerasCM } from './ReportDownloaderVaquerasCM';
import { ReportDownloaderVitae } from './ReportDownloaderVitae';
import { ReportDownloaderFLT } from './ReportDownloaderFLT';
import { ReportDownloaderPHPuntarena } from './ReportDownloaderPHPuntarena';
import { ReportDownloaderPGC } from './ReportDownloaderPGC';
import { SendToFlexioButton } from './SendToFlexioButton';
import { ReportHistory } from './ReportHistory';
import { authContext } from '../ProvideAuth.js';
import axios from 'axios';
import { ReportDownloaderPHBuenaventura } from './ReportDownloaderPHBuenaventura';
import { ReportDownloaderMedicinasGenerales } from './ReportDownloaderMedicinasGenerales';
import { ReportDownloaderRefripolar } from './ReportDownloaderRefripolar';
import { ReportDownloaderBahia } from './ReportDownloaderBahia';
import { ReportDownloaderMcGowen } from './ReportDownloaderMcGowen';
import { ReportDownloaderMcGowenEskema } from './ReportDownloaderMcGowenEskema';
import { ReportDownloaderMcGowenSage50 } from './ReportDownloaderMcGowenSage50';
import { ReportDownloaderPCACevicheria } from './ReportDownloaderPCACevicheria';
import { ReportDownloaderPCABlueAdventure } from './ReportDownloaderPCABlueAdventure';
import { ReportDownloaderPCAGlobalLounge } from './ReportDownloaderPCAGlobalLounge';
import { ReportDownloaderFeduro } from './ReportDownloaderFeduro';
import { ReportDownloaderNSolar } from './ReportDownloaderNSolar';

const { Text } = Typography;

function ReportSingleModal(props) {
  const auth = useContext(authContext);
  const [submittedToggle, setSubmittedToggle] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState();
  const [reportExpensesList, setReportExpensesList] = useState();
  const [tokenToShareReport, setTokenToShareReport] = useState('');
  const [copyingToClipboard, setCopyingToClipboard] = useState(false);
  const [text, setText] = useState('');

  function getIndex(expenseObject) {
    return reportExpensesList.findIndex((obj) => expenseObject === obj);
  }

  function getExpenseObject(index) {
    return reportExpensesList[index];
  }

  function previousExpense() {
    const currentIndex = getIndex(selectedExpense);
    if (currentIndex > 0) {
      setSelectedExpense(getExpenseObject(currentIndex - 1));
    }
  }
  const nextExpense = () => {
    const currentIndex = getIndex(selectedExpense);
    if (currentIndex < reportExpensesList.length - 1) {
      setSelectedExpense(getExpenseObject(currentIndex + 1));
    }
  };

  useEffect(() => {
    // Fetch the token when the modal is opened
    const fetchToken = async () => {
      try {
        const response = await axios({
          method: 'get',
          url:
            props.API_domain +
            'getTokenToShareReport?reportId=' +
            props.report_id,
          auth: {
            username: auth.email,
            password: auth.token,
          },
        });
        setTokenToShareReport(response.data.token);
      } catch (error) {
        console.error('Error fetching token:', error);
      }
    };

    fetchToken();
  }, [props.API_domain, props.report_id, auth.email, auth.token]);

  const copyLink = async () => {
    setCopyingToClipboard(true);
    try {
      const text =
        'https://www.herofacturas.com/sharedReport?reportId=' +
        props.report_id +
        '&token=' +
        tokenToShareReport;

      if (navigator.clipboard) {
        // Using the Clipboard API
        await navigator.clipboard.writeText(text);
        message.info('Link al reporte copiado ✅', 3);
      } else {
        console.error('Clipboard not supported');
      }
    } catch (error) {
      console.log('Error copying to clipboard:', error);
    } finally {
      setCopyingToClipboard(false);
    }
  };

  const menu = (
    <>
      <Menu>
        <Menu.Item>
          <ReportDownloaderCustom
            API_domain={props.API_domain}
            report_id={props.report_id}
          />
        </Menu.Item>
        <Menu.Item>
          <ReportDownloaderGeneral
            API_domain={props.API_domain}
            report_id={props.report_id}
          />
        </Menu.Item>
        <Menu.Item>
          <ReportDownloaderInforme43
            API_domain={props.API_domain}
            report_id={props.report_id}
          />
        </Menu.Item>
        <Menu.Item>
          <ReportDownloaderPeachtree
            API_domain={props.API_domain}
            report_id={props.report_id}
          />
        </Menu.Item>
        <Menu.Item>
          <ReportDownloaderQuickbooks
            API_domain={props.API_domain}
            report_id={props.report_id}
          />
        </Menu.Item>
        {props.team.includes('GR COMPRAS') ||
        props.team.includes('GR TALLER') ||
        props.team.includes('GR EQUIPOS') ||
        props.team.includes('GR Caja Luis - 02') ? (
          <>
            <Menu.Item>
              <ReportDownloaderGrupoResidencial01
                API_domain={props.API_domain}
                report_id={props.report_id}
              />
            </Menu.Item>
            <Menu.Item>
              <ReportDownloaderGrupoResidencial02
                API_domain={props.API_domain}
                report_id={props.report_id}
              />
            </Menu.Item>
          </>
        ) : (
          ''
        )}
        {props.team.includes('GR Caja') ? (
          <>
            <Menu.Item>
              <ReportDownloaderGrupoResidencial01
                API_domain={props.API_domain}
                report_id={props.report_id}
              />
            </Menu.Item>
          </>
        ) : (
          ''
        )}
        {props.team.includes('- Tarjeta de Credito') ? (
          <>
            <Menu.Item>
              <ReportDownloaderGrupoResidencialCards
                API_domain={props.API_domain}
                report_id={props.report_id}
              />
            </Menu.Item>
          </>
        ) : (
          ''
        )}
        {auth.email.includes('barrio') ? (
          <Menu.Item>
            <ReportDownloaderBarrioPizza
              API_domain={props.API_domain}
              report_id={props.report_id}
            />
          </Menu.Item>
        ) : (
          ''
        )}
        {auth.email.includes('concretex') ? (
          <Menu.Item>
            <ReportDownloaderConcretex
              API_domain={props.API_domain}
              report_id={props.report_id}
            />
          </Menu.Item>
        ) : (
          ''
        )}
        {auth.email.includes('riga') ? (
          <Menu.Item>
            <ReportDownloaderRiga
              API_domain={props.API_domain}
              report_id={props.report_id}
            />
          </Menu.Item>
        ) : (
          ''
        )}
        {auth.email.includes('verticalpanama') ||
        auth.email.includes('noreplyherofacturas') ? (
          <Menu.Item>
            <ReportDownloaderVertical
              API_domain={props.API_domain}
              report_id={props.report_id}
            />
          </Menu.Item>
        ) : (
          ''
        )}
        {props.team.includes('Asyr Group') ? (
          <Menu.Item>
            <ReportDownloaderAsyr
              API_domain={props.API_domain}
              report_id={props.report_id}
            />
          </Menu.Item>
        ) : (
          ''
        )}
        {auth.email.includes('epvmarine') ? (
          <Menu.Item>
            <ReportDownloaderTwoOceans
              API_domain={props.API_domain}
              report_id={props.report_id}
            />
          </Menu.Item>
        ) : (
          ''
        )}
        {auth.email.includes('acelmar') ? (
          <Menu.Item>
            <ReportDownloaderAcelmar
              API_domain={props.API_domain}
              report_id={props.report_id}
            />
          </Menu.Item>
        ) : (
          ''
        )}
        {props.team.includes('VITAE') ? (
          <Menu.Item>
            <ReportDownloaderVitae
              API_domain={props.API_domain}
              report_id={props.report_id}
            />
          </Menu.Item>
        ) : (
          ''
        )}
        {props.team.includes('Vaqueras') ? (
          <Menu.Item>
            <ReportDownloaderVaqueras
              API_domain={props.API_domain}
              report_id={props.report_id}
            />
          </Menu.Item>
        ) : (
          ''
        )}
        {props.team.includes('Vaqueras') ? (
          <Menu.Item>
            <ReportDownloaderVaquerasCM
              API_domain={props.API_domain}
              report_id={props.report_id}
            />
          </Menu.Item>
        ) : (
          ''
        )}
        {props.team.includes('FLT Estudio') ? (
          <Menu.Item>
            <ReportDownloaderFLT
              API_domain={props.API_domain}
              report_id={props.report_id}
            />
          </Menu.Item>
        ) : (
          ''
        )}
        {props.team.includes('PH Puntarena') ? (
          <Menu.Item>
            <ReportDownloaderPHPuntarena
              API_domain={props.API_domain}
              report_id={props.report_id}
            />
          </Menu.Item>
        ) : (
          ''
        )}
        {props.team.includes('PH Buenaventura') ? (
          <Menu.Item>
            <ReportDownloaderPHBuenaventura
              API_domain={props.API_domain}
              report_id={props.report_id}
            />
          </Menu.Item>
        ) : (
          ''
        )}
        {props.team.includes('MG -') ? (
          <Menu.Item>
            <ReportDownloaderMedicinasGenerales
              API_domain={props.API_domain}
              report_id={props.report_id}
            />
          </Menu.Item>
        ) : (
          ''
        )}
        {props.team.includes('Refripolar') ? (
          <Menu.Item>
            <ReportDownloaderRefripolar
              API_domain={props.API_domain}
              report_id={props.report_id}
            />
          </Menu.Item>
        ) : (
          ''
        )}
        {props.team.includes('Cevicheria Sushi y Sea Food') ? (
          <Menu.Item>
            <ReportDownloaderPCACevicheria
              API_domain={props.API_domain}
              report_id={props.report_id}
            />
          </Menu.Item>
        ) : (
          ''
        )}
        {props.team.includes('Blue Adventure Panama') ? (
          <Menu.Item>
            <ReportDownloaderPCABlueAdventure
              API_domain={props.API_domain}
              report_id={props.report_id}
            />
          </Menu.Item>
        ) : (
          ''
        )}
        {props.team.includes('Global Lounge OP PTY') ? (
          <Menu.Item>
            <ReportDownloaderPCAGlobalLounge
              API_domain={props.API_domain}
              report_id={props.report_id}
            />
          </Menu.Item>
        ) : (
          ''
        )}
        {props.team.includes('NSOLAR') ? (
          <Menu.Item>
            <ReportDownloaderNSolar
              API_domain={props.API_domain}
              report_id={props.report_id}
            />
          </Menu.Item>
        ) : (
          ''
        )}
        {auth.email.includes('mcgowen') ||
        auth.email.includes('noreplyherofacturas') ? (
          <>
            <Menu.Item>
              <ReportDownloaderMcGowenSage50
                API_domain={props.API_domain}
                report_id={props.report_id}
              />
            </Menu.Item>
            <Menu.Item>
              <ReportDownloaderMcGowenEskema
                API_domain={props.API_domain}
                report_id={props.report_id}
              />
            </Menu.Item>
            <Menu.Item>
              <ReportDownloaderMcGowen
                API_domain={props.API_domain}
                report_id={props.report_id}
              />
            </Menu.Item>
          </>
        ) : (
          ''
        )}
        {auth.email.includes('bahia') || auth.email.includes('noreply') ? (
          <Menu.Item>
            <ReportDownloaderBahia
              API_domain={props.API_domain}
              report_id={props.report_id}
            />
          </Menu.Item>
        ) : (
          ''
        )}
        {props.team.includes('ADOK') ||
        props.team.includes('PRODUCTORES -') ||
        props.team.includes(' - 021') ||
        props.team.includes(' - 137') ? (
          <Menu.Item>
            <ReportDownloaderPGC
              API_domain={props.API_domain}
              report_id={props.report_id}
            />
          </Menu.Item>
        ) : (
          ''
        )}
        {props.team.includes('FEDURO') ? (
          <Menu.Item>
            <ReportDownloaderFeduro
              API_domain={props.API_domain}
              report_id={props.report_id}
            />
          </Menu.Item>
        ) : (
          ''
        )}
        {auth.email.includes('herofacturas') ? (
          <>
            <Menu.Item>
              <ReportDownloaderZohoExpenses
                API_domain={props.API_domain}
                report_id={props.report_id}
              />
            </Menu.Item>
            <Menu.Item>
              <ReportDownloaderZohoExpensesThorough
                API_domain={props.API_domain}
                report_id={props.report_id}
              />
            </Menu.Item>
          </>
        ) : (
          ''
        )}
        <Menu.Item>
          <ReportDownloaderImages
            API_domain={props.API_domain}
            report_id={props.report_id}
          />
        </Menu.Item>
        {/* <Menu.Item>
          <ReportDownloaderImagesPDF
            API_domain={props.API_domain}
            report_id={props.report_id}
          />
        </Menu.Item> */}
      </Menu>
    </>
  );

  return (
    <Modal
      title={
        <>
          <div>{props.report_title}</div>
          <span style={{ color: 'grey', fontSize: '14px' }}>{props.team}</span>
        </>
      }
      visible={true}
      footer={
        <>
          <Row>
            <Col span={10}>
              <ReportHistory
                API_domain={props.API_domain}
                report_id={props.report_id}
                submittedToggle={submittedToggle}
              />
            </Col>
            {props.report_comments ? (
              <Col span={14}>
                <Text type='secondary'>
                  Comentarios: {props.report_comments}
                </Text>
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <Row>
            <Col span={8}></Col>
            <Col span={8} style={{ textAlign: 'center' }}>
              <ReportActionButton
                API_domain={props.API_domain}
                report_id={props.report_id}
                onSubmit={props.onSubmit}
                handleOk={props.handleOk}
                setSubmittedToggle={setSubmittedToggle}
                submittedToggle={submittedToggle}
              />
            </Col>
            <Col span={8} style={{ textAlign: 'right' }}>
              {props.mobile ? (
                <></>
              ) : (
                <>
                  {/* <SendToFlexioButton
                    API_domain={props.API_domain}
                    report_id={props.report_id}
                    onSubmit={props.onSubmit}
                    handleOk={props.handleOk}
                    setSubmittedToggle={setSubmittedToggle}
                    submittedToggle={submittedToggle}
                  /> */}

                  <Button
                    onClick={async () => {
                      await copyLink();
                    }}
                    type='secondary'
                    style={{ marginRight: 10 }}
                  >
                    {copyingToClipboard ? (
                      'Copiando...'
                    ) : (
                      <>
                        <LinkOutlined /> Compartir
                      </>
                    )}
                  </Button>
                  <Dropdown overlay={menu} placement='bottomRight' arrow>
                    <Button>Descargar</Button>
                  </Dropdown>
                </>
              )}
            </Col>
          </Row>
        </>
      }
      onCancel={props.handleCancel}
      width={1400}
      bodyStyle={{
        height: 'calc(100vh - 420px)',
        overflow: 'auto',
        paddingTop: 4,
      }}
      centered
      // style={{ paddingTop: 40 }}
    >
      {selectedExpense && (
        <>
          {props.mobile ? (
            <ExpenseModalMobile
              record={selectedExpense}
              visible={true}
              title={'Gasto'}
              imageSource={selectedExpense.factura}
              handleCancel={() => setSelectedExpense(null)}
              handleOk={() => setSelectedExpense(null)}
              // onSubmit={onSubmit}
              expense={selectedExpense}
              API_domain={props.API_domain}
              team={props.team}
              previous={previousExpense}
              next={nextExpense}
            />
          ) : (
            <ReportedExpenseModal
              record={selectedExpense}
              visible={true}
              title={props.report_title}
              imageSource={selectedExpense.factura}
              handleCancel={() => setSelectedExpense(null)}
              handleOk={() => setSelectedExpense(null)}
              // onSubmit={onSubmit}
              expense={selectedExpense}
              API_domain={props.API_domain}
              team={props.team}
              previousExpense={previousExpense}
              nextExpense={nextExpense}
            />
          )}
        </>
      )}
      <FinishedReportTable
        API_domain={props.API_domain}
        report_id={props.report_id}
        setReportExpensesList={setReportExpensesList}
        openModal={setSelectedExpense}
        setSelectedExpense={setSelectedExpense}
        mobile={props.mobile}
      />
    </Modal>
  );
}

export { ReportSingleModal };
