import React, { useState, useEffect } from 'react';
import { Form, Input, message, Button, Row, Col } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const BGIntegration = (props) => {
  const [account, setAccount] = useState(
    props.bgCredentials.login_info.account || ''
  );
  const [password, setPassword] = useState(
    props.bgCredentials.login_info.password || ''
  );
  const [username, setUsername] = useState(
    props.bgCredentials.login_info.username || ''
  );
  const [questionMap, setQuestionMap] = useState(
    props.bgCredentials.login_info.question_map || {}
  );
  const [loading, setLoading] = useState(false); // State to manage the loading status

  // Handle change for dynamic question map inputs
  const handleQuestionChange = (key, value) => {
    setQuestionMap((prevMap) => ({
      ...prevMap,
      [key]: value,
    }));
  };

  const handleSave = async () => {
    setLoading(true); // Start loading

    try {
      const updatedCredentials = {
        accounting_client_id: props.bgCredentials.accounting_client_id,
        id: props.bgCredentials.id, // Unique ID for the Municipio configuration
        site: 'bg',
        login_info: {
          account,
          password,
          username,
          question_map: questionMap, // Include the updated question map
        },
      };

      // Send only the relevant credentials to the backend
      await props.updateAccountingClientConfig(updatedCredentials);
      message.success('Actualización exitosa');
    } catch (error) {
      console.error('Error during update:', error);
      message.error('Error actualizando');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div style={{ marginBottom: 32, marginTop: 32 }}>
      <h3 style={{ marginLeft: 4, textAlign: 'left' }}>BANCO GENERAL 🦸‍♂️</h3>
      <Form.Item label='Tipo de cuenta' style={{ marginLeft: 96 }}>
        <Row>
          <Col span={22}>
            <Input
              value={account}
              onChange={(e) => setAccount(e.target.value)}
            />
          </Col>
          <Col span={2}>
            <Button
              icon={<CopyOutlined />}
              type='text'
              style={{ color: 'grey' }}
              onClick={() => props.copyToClipboard(account)}
            />
          </Col>
        </Row>
      </Form.Item>
      <Form.Item label='Contraseña' style={{ marginLeft: 96 }}>
        <Row>
          <Col span={22}>
            <Input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Col>
          <Col span={2}>
            <Button
              icon={<CopyOutlined />}
              type='text'
              style={{ color: 'grey' }}
              onClick={() => props.copyToClipboard(password)}
            />
          </Col>
        </Row>
      </Form.Item>
      <Form.Item label='Usuario' style={{ marginLeft: 96 }}>
        <Row>
          <Col span={22}>
            <Input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Col>
          <Col span={2}>
            <Button
              icon={<CopyOutlined />}
              type='text'
              style={{ color: 'grey' }}
              onClick={() => props.copyToClipboard(username)}
            />
          </Col>
        </Row>
      </Form.Item>
      {Object.keys(questionMap).map((key) => (
        <Form.Item label={key} style={{ marginLeft: 96 }} key={key}>
          <Row>
            <Col span={22}>
              <Input
                value={questionMap[key]}
                onChange={(e) => handleQuestionChange(key, e.target.value)}
              />
            </Col>
            <Col span={2}>
              <Button
                icon={<CopyOutlined />}
                type='text'
                style={{ color: 'grey' }}
                onClick={() => props.copyToClipboard(questionMap[key])}
              />
            </Col>
          </Row>
        </Form.Item>
      ))}
      <div style={{ textAlign: 'left', marginLeft: 96 }}>
        <Button
          type='primary'
          onClick={handleSave}
          loading={loading} // Apply loading state to the button
          style={{ marginRight: 16 }}
        >
          Guardar
        </Button>
        <Button
          type='secondary'
          onClick={() => console.log('validating')}
          style={{
            backgroundColor: 'hsl(0, 0%, 95%)',
            borderColor: 'hsl(0, 0%, 95%)',
          }}
          disabled
        >
          Validar Banco General
          {/* {props.renderValidationIcon(props.bgCredentials.dgi_last_verify)} */}{' '}
          ❌
        </Button>
      </div>
    </div>
  );
};

export default BGIntegration;
