// AccountingAccountsTable.js

import React, { useState, useEffect, useContext } from 'react';
import { Table, message, Button, Modal, Form, Input, Select } from 'antd';
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import HeroAxios from '../../helpers/HeroAxios.js';
import { authContext } from '../../ProvideAuth.js';

function AccountingAccountsTable({ API_domain, accountingClientCompany }) {
  const auth = useContext(authContext);
  const [accountsData, setAccountsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newAccountName, setNewAccountName] = useState('');
  const [selectedParentAccountId, setSelectedParentAccountId] = useState(null);
  const [selectedAccountType, setSelectedAccountType] = useState('');
  const [parentAccountType, setParentAccountType] = useState('');
  const [accountTypes, setAccountTypes] = useState([
    'bank',
    'cash',
    'current_asset',
    'other_current_asset',
    'other_asset',
    'fixed_asset',
    'credit_card',
    'current_liability',
    'other_current_liability',
    'long_term_liability',
    'other_liability',
    'equity',
    'income',
    'sales',
    'other_income',
    'expense',
    'cost_of_goods_sold',
    'other_expense',
  ]);


  const fetchAccounts = async () => {
    setLoading(true);
    try {
      const response = await HeroAxios.post('getAccountHierarchy', {
        client_id: accountingClientCompany.accounting_client_id,
      });
      setAccountsData(response.data.accounts);
    } catch (error) {
      console.error('Error fetching account hierarchy:', error);
      message.error('Error al obtener la jerarquía de cuentas');
    }
    setLoading(false);
  };

  const handleCreateAccount = async () => {
    if (!newAccountName) {
      message.error('Por favor ingrese el nombre de la cuenta');
      return;
    }
    if (!selectedAccountType) {
      message.error('Por favor seleccione el tipo de cuenta');
      return;
    }
    setLoading(true);
    try {
      await HeroAxios.post('createAccount', {
        client_id: accountingClientCompany.accounting_client_id,
        zoho_account_name: newAccountName,
        parent_account_id: selectedParentAccountId,
        zoho_account_type: selectedAccountType,
      });
      message.success('Cuenta creada exitosamente');
      setIsModalVisible(false);
      setNewAccountName('');
      setSelectedParentAccountId(null);
      setSelectedAccountType('');
      setParentAccountType('');
      fetchAccounts(); // refresh accounts data
    } catch (error) {
      console.error('Error creating account:', error);
      message.error('Error al crear la cuenta');
    }
    setLoading(false);
  };
  
  useEffect(() => {
    fetchAccounts();
  }, [API_domain, accountingClientCompany, auth]);

  const handleExpand = (expanded, record) => {
    setExpandedKeys((prevKeys) => {
      if (expanded) {
        return [...prevKeys, record.id];
      } else {
        return prevKeys.filter((key) => key !== record.id);
      }
    });
  };

  // Expand all rows
  const handleExpandAll = () => {
    const getAllKeys = (nodes) => {
      let keys = [];
      nodes.forEach((node) => {
        keys.push(node.id);
        if (node.children) {
          keys = keys.concat(getAllKeys(node.children));
        }
      });
      return keys;
    };
    setExpandedKeys(getAllKeys(accountsData));
  };

  // Collapse all rows
  const handleCollapseAll = () => {
    setExpandedKeys([]);
  };
  // Define table columns
  const columns = [
    {
      title: 'Nombre de la Cuenta',
      dataIndex: 'zoho_account_name',
      key: 'zoho_account_name',
      render: (text, record) =>
        record.is_category ? <strong>{text}</strong> : text,
    },
    {
      title: 'Tipo de Cuenta',
      dataIndex: 'zoho_account_type',
      key: 'zoho_account_type',
    },
  ];

  const handleParentAccountChange = (value) => {
    setSelectedParentAccountId(value);
    const parentAccount = flattenedAccounts.find((account) => account.id === value);
    if (parentAccount) {
      setParentAccountType(parentAccount.zoho_account_type);
      setSelectedAccountType(parentAccount.zoho_account_type);
    }
  };
  
  const flattenAccounts = (accounts) => {
    let flatList = [];
    accounts.forEach((account) => {
      flatList.push(account);
      if (account.children) {
        flatList = flatList.concat(flattenAccounts(account.children));
      }
    });
    return flatList;
  };
  
  const flattenedAccounts = flattenAccounts(accountsData);
  const showCreateAccountModal = () => {
    setIsModalVisible(true);
  };
  const handleModalCancel = () => {
    setIsModalVisible(false);
    setNewAccountName('');
    setSelectedParentAccountId(null);
    setSelectedAccountType('');
    setParentAccountType('');
  };

  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <Button style={{ marginRight: 8 }} onClick={handleCollapseAll}>
          <FullscreenExitOutlined /> Colapsar
        </Button>
        <Button style={{ marginRight: 8 }} onClick={handleExpandAll}>
          <FullscreenOutlined /> Expandir
        </Button>
        <Button type="primary" style={{ marginRight: 8 }} onClick={showCreateAccountModal}>
          Crear nueva cuenta
        </Button>
      </div>
      <Modal
        title="Crear nueva cuenta"
        visible={isModalVisible}
        onCancel={handleModalCancel}
        onOk={handleCreateAccount}
      >
        <Form layout="vertical">
          <Form.Item label="Nombre de la cuenta" required>
            <Input value={newAccountName} onChange={(e) => setNewAccountName(e.target.value)} />
          </Form.Item>
          <Form.Item label="Cuenta padre">
            <Select
              value={selectedParentAccountId}
              onChange={handleParentAccountChange}
              placeholder="Seleccione una cuenta padre (opcional)"
            >
              {flattenedAccounts.map((account) => (
                <Select.Option key={account.id} value={account.id}>
                  {account.zoho_account_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Tipo de cuenta" required>
            <Select
              value={selectedAccountType}
              onChange={(value) => setSelectedAccountType(value)}
              placeholder="Seleccione un tipo de cuenta"
            >
              {accountTypes.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Table
        dataSource={accountsData}
        columns={columns}
        loading={loading}
        pagination={false}
        expandable={{
          expandedRowKeys: expandedKeys,
          onExpand: handleExpand,
        }}
        rowKey={(record) => record.id}
      />
    </div>
  );
}

export { AccountingAccountsTable };
