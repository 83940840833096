import { Button, Empty, Table, Modal, Image } from 'antd';
import { DownloadOutlined, BankOutlined, CreditCardOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../../ProvideAuth.js';
import axios from 'axios';
import { FormattedUSD } from '../FormattedUSD.js';
import { PdfViewer } from '../PdfViewer.js';
import * as XLSX from 'xlsx';
import moment from 'moment';

function AccountingFinancialStatementsModal(props) {
  const auth = useContext(authContext);
  const [accountEntries, setAccountEntries] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAccountEntries = () => {
    // message.info('Loading account entries...');
    setLoading(true);
    axios({
      method: 'post',
      url: props.API_domain + 'getAccountEntries',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        account_id: props.modalContent.account_id,
      },
    }).then((response) => {
      setLoading(false);
      const month = props.modalContent.month;
      const startDate = moment(month, 'YYYY-MM')
        .startOf('month')
        .format('YYYY-MM-DD');
      const endDate = moment(month, 'YYYY-MM')
        .endOf('month')
        .format('YYYY-MM-DD');

      const filteredEntries = response.data
        .filter((entry) => entry.date >= startDate && entry.date <= endDate)
        .sort((a, b) => new Date(a.date) - new Date(b.date));

      setAccountEntries(filteredEntries);
    });
  };

  useEffect(() => {
    if (props.modalContent.account_id) {
      getAccountEntries();
    }
  }, [props.modalContent.account_id, props.modalContent.month]);

  const exportToExcel = (data) => {
    if (!Array.isArray(data)) {
      console.error('Data is not an array:', data);
      return;
    }

    const formattedData = data.map((entry) => ({
      Fecha: entry.date,
      Monto: entry.amount,
      Comentarios: entry.comments,
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Account Entries');
    XLSX.writeFile(workbook, props.modalContent.subcategory + '.xlsx');

    const trackingData = {
      event: 'exportAccountingEntriesToExcel',
      properties: {},
    };
    axios({
      method: 'post',
      url: props.API_domain + 'trackFrontEndEvent',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: trackingData,
    })
      .then((response) => {
        console.log('Event tracked:', response);
      })
      .catch((error) => {
        console.error('Error tracking event:', error);
      });
  };

  const columns = [
    {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'date',
      width: 150,
    },
    {
      title: 'Monto',
      dataIndex: 'amount',
      key: 'amount',
      width: 150,
      render: (amount, record) => (
        <div style={{ textAlign: 'right' }}>
          <FormattedUSD
            total={props.accountingClientCompany === 4 ? amount * 0.62 : amount}
          />
        </div>
      ),
    },
    {
      title: 'Comentarios',
      dataIndex: 'comments',
      key: 'comments',
      render: (comments) => (
        <span
          title={
            props.accountingClientCompany === 4 && comments.length > 6
              ? comments
              : null
          }
        >
          {props.accountingClientCompany === 4 && comments.length > 6
            ? comments.slice(0, 6) + '...'
            : comments}
        </span>
      ),
    },
    {
      title: '', // No title for the icon column
      dataIndex: 'type_of_mm',
      key: 'type_of_mm',
      render: (type_of_mm) => (
        <>
          {type_of_mm === 'bank' && <BankOutlined />}
          {type_of_mm === 'card' && <CreditCardOutlined />}
        </>
      ),
      width: 50, // Adjust the width to keep it small
    },
    {
      title: '', // No title for this combined column
      key: 'cashflow_url',
      render: (record) => (
        <div>
          {record.cufe_url && (
            <PdfViewer pdfUrl={record.cufe_url} />
          )}
          {record.cashflow_url && (
            <Image width={24} src={record.cashflow_url} alt="Cashflow" />
          )}
        </div>
      ),
      width: 100, // Adjust width to fit both icons
    },
  ];

  return (
    <Modal
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            {props.modalContent.subcategory}{' '}
            <span style={{ color: 'grey' }}>{props.modalContent.month}</span>
          </div>
          <Button
            key='export'
            style={{ margin: 4, marginRight: 30, textAlign: 'right' }}
            onClick={() => exportToExcel(accountEntries)}
            disabled={props.accountingClientCompany === 4}
          >
            <DownloadOutlined /> Excel
          </Button>
        </div>
      }
      visible={props.isModalVisible}
      onOk={props.onOk}
      onCancel={() => {
        props.onCancel();
        setAccountEntries([]);
      }}
      width={1200}
      footer={null}
    >
      <Table
        columns={columns}
        dataSource={accountEntries}
        rowKey='entry_id'
        pagination={false}
        loading={loading}
        locale={{
          emptyText: (
            <Empty
              style={{ paddingTop: '8vh', paddingBottom: '8vh' }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'No hay entradas para este mes'}
            />
          ),
        }}
        summary={(pageData) => {
          let totalAmount = 0;
          pageData.forEach(({ entry_type, amount }) => {
            totalAmount += amount;
          });

          return (
            <Table.Summary.Row style={{ fontWeight: 'bold' }}>
              <Table.Summary.Cell colSpan={1}>Total</Table.Summary.Cell>
              <Table.Summary.Cell>
                <div style={{ textAlign: 'right' }}>
                  {props.accountingClientCompany === 4 ? (
                    <FormattedUSD total={totalAmount * 0.62} />
                  ) : (
                    <FormattedUSD total={totalAmount} />
                  )}
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell colSpan={2} />
            </Table.Summary.Row>
          );
        }}
      />
    </Modal>
  );
}

export { AccountingFinancialStatementsModal };
