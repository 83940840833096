import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { formatDateSpanishLong } from '../../utils';
import axios from 'axios';
import { createCoverPage } from './GeneratePDFCoverPage.js';

const formatNumberWithCommas = (number) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
};

export const generatePDF = (
  outputOnlyBlob,
  accountingClientCompany,
  incomeStatementRecentTimePeriods,
  balanceSheetRecentTimePeriods,
  balanceSheetData,
  incomeStatementData,
  API_domain,
  auth
) => {
  const doc = new jsPDF();

  // Function to load the Georgia font
  const loadFont = async (url) => {
    const response = await fetch(url);
    const buffer = await response.arrayBuffer();
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const addGeorgiaFont = (base64) => {
    doc.addFileToVFS('Georgia.ttf', base64);
    doc.addFont('Georgia.ttf', 'Georgia', 'normal');
  };

  // Helper functions
  const loadImageBase64 = (base64, mimeType = 'image/png') => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = () => {
        console.warn(`Failed to load base64 image`);
        resolve(null); // Resolve with null if image fails to load
      };
      // Set the src with the appropriate base64 data URL format
      img.src = `data:${mimeType};base64,${base64}`;
    });
  };

  const loadImage = (src) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = () => {
        console.warn(`Failed to load image: ${src}`);
        resolve(null); // Resolve with null if image fails to load
      };
      img.src = src;
    });
  };

  const addCoverPage = async (doc) => {
    if (!accountingClientCompany) {
      console.warn('No accounting client company data available.');
      return;
    }

    const heroLogoSrc = '/heroLogoSmall.png'; // Adjust path as needed
    const title = `${accountingClientCompany.name}`;

    try {
      // Load both images concurrently
      const [logo1, logo2] = await Promise.all([
        loadImageBase64(accountingClientCompany.logo_base64),
        loadImage(heroLogoSrc),
      ]);

      // Function to calculate logo dimensions while maintaining aspect ratio
      const calculateLogoDimensions = (img) => {
        if (!img) return { width: 0, height: 0 };
        const aspectRatio = img.width / img.height;
        const maxLogoHeight = 50;
        const maxLogoWidth = 100;

        let width = maxLogoHeight * aspectRatio;
        let height = maxLogoHeight;

        if (width > maxLogoWidth) {
          width = maxLogoWidth;
          height = maxLogoWidth / aspectRatio;
        }

        return { width, height };
      };

      // Calculate dimensions for both logos
      const logo1Dimensions = calculateLogoDimensions(logo1);
      const logo2Dimensions = calculateLogoDimensions(logo2);

      // Set initial positions
      const pageWidth = doc.internal.pageSize.getWidth();
      const xOffset1 = 10;
      const yOffset = 10;

      let currentX = xOffset1;
      let maxHeight = 0;

      // Add first logo if loaded
      if (logo1) {
        doc.addImage(
          logo1.src,
          'PNG',
          currentX,
          yOffset,
          logo1Dimensions.width,
          logo1Dimensions.height
        );
        currentX += logo1Dimensions.width + 10; // Add spacing between logos
        maxHeight = Math.max(maxHeight, logo1Dimensions.height);
      } else {
        console.warn('Accounting client logo failed to load.');
      }

      // Add second logo if loaded
      if (logo2) {
        doc.addImage(
          logo2.src,
          'PNG',
          currentX,
          yOffset,
          logo2Dimensions.width,
          logo2Dimensions.height
        );
        maxHeight = Math.max(maxHeight, logo2Dimensions.height);
      } else {
        console.warn('Hero logo failed to load.');
      }

      // Add title and report date below the logos
      doc.setFont('Georgia');
      doc.setFontSize(20);
      doc.text(title, 20, maxHeight + 30);

      const reportDate = formatDateSpanishLong(
        getLastDayOfMonth(
          incomeStatementRecentTimePeriods[
            incomeStatementRecentTimePeriods.length - 1
          ]
        )
      );

      doc.text(`Reporte contable al ${reportDate}`, 20, maxHeight + 48);
      doc.setFontSize(12);

      // Optionally, you can add more content or styling here
    } catch (error) {
      console.error('Error adding cover page:', error);
      // Handle errors as needed, possibly by adding default text or images
    }
  };

  const convertTableData = (data, timePeriods) => {
    const formattedData = [];

    const processRow = (row, level = 0) => {
      formattedData.push({
        subcategory: row.subcategory,
        values: row.values.map((val) =>
          formatNumberWithCommas(parseFloat(val))
        ), // Apply formatting here
        level: level,
        isParent: row.children.length > 0,
      });
      row.children.forEach((child) => processRow(child, level + 1));
    };

    data.forEach((row) => processRow(row));
    return formattedData;
  };

  const createTable = (doc, data, timePeriods, startY) => {
    doc.autoTable({
      head: [
        [
          { content: 'Cuenta', styles: { halign: 'left' } },
          ...timePeriods.map((period) => ({
            content: period,
            styles: { halign: 'right' },
          })),
        ],
      ],
      body: data.map((item) => [
        {
          content: item.subcategory,
          styles: {
            fontStyle: item.isParent ? 'bold' : 'normal',
            cellPadding: {
              left: item.level * 10,
              right: 0,
              top: 2,
              bottom: 2,
            },
            halign: 'left',
          },
        },
        ...item.values.map((val) => ({
          content: val,
          styles: {
            fontStyle: item.isParent ? 'bold' : 'normal',
            halign: 'right',
          },
        })),
      ]),
      startY: startY,
      headStyles: { fillColor: [116, 116, 199] },
    });
    return doc.previousAutoTable.finalY;
  };

  const balanceSheetDataForPDF = convertTableData(
    balanceSheetData,
    balanceSheetRecentTimePeriods
  );
  const incomeStatementDataForPDF = convertTableData(
    incomeStatementData,
    incomeStatementRecentTimePeriods
  );

  const addFooter = (doc) => {
    const exportDate = `Exportado el ${formatDateSpanishLong(new Date())}`;
    const pageCount = doc.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.text(
        `${i}`,
        doc.internal.pageSize.width - 10,
        doc.internal.pageSize.height - 10,
        { align: 'right' }
      );
      doc.text(exportDate, 10, doc.internal.pageSize.height - 10);
    }
  };

  // Load the Georgia font and add the cover page
  return loadFont('/georgia.ttf') // Adjust the path if necessary
    .then((base64) => {
      addGeorgiaFont(base64); // Add the Georgia font
      return createCoverPage(
        doc,
        accountingClientCompany,
        incomeStatementRecentTimePeriods
      );
    })
    .then(() => {
      doc.addPage();
      doc.setFontSize(16);
      doc.text('Balance', 16, 20);

      let finalY = createTable(
        doc,
        balanceSheetDataForPDF,
        balanceSheetRecentTimePeriods,
        30
      );

      doc.addPage();
      doc.setFontSize(16);
      doc.text('Estado de Resultados', 16, 20);

      createTable(
        doc,
        incomeStatementDataForPDF,
        incomeStatementRecentTimePeriods,
        30
      );

      // Add page numbers
      addFooter(doc);

      const lastTimePeriod =
        incomeStatementRecentTimePeriods[
          incomeStatementRecentTimePeriods.length - 1
        ];
      const lastDayOfMonth = getLastDayOfMonth(lastTimePeriod);
      const file_name = `${lastDayOfMonth}_reporte_contable_${accountingClientCompany.name}.pdf`;

      if (outputOnlyBlob) {
        return doc.output('blob');
      } else {
        // Track the event
        const trackingData = {
          event: 'exportFinancialStatementsPDF',
          properties: {},
        };
        axios({
          method: 'post',
          url: `${API_domain}trackFrontEndEvent`,
          auth: {
            username: auth.email,
            password: auth.token,
          },
          data: trackingData,
        })
          .then((response) => {
            console.log('Event tracked:', response);
          })
          .catch((error) => {
            console.error('Error tracking event:', error);
          });
        console.log('Saving PDF Document:', file_name);
        doc.save(file_name);
        return null;
      }
    })
    .catch((error) => {
      console.error(
        'Error loading the font or creating the cover page:',
        error
      );
      return null;
    });
};

const getLastDayOfMonth = (yearMonth) => {
  const [year, month] = yearMonth.split('-').map(Number);
  const date = new Date(year, month, 1);
  date.setDate(date.getDate() - 1);
  return date.toISOString().split('T')[0];
};
