import {
  CommentOutlined,
  FlagFilled,
  FlagOutlined,
  LoadingOutlined,
  SearchOutlined,
  LinkOutlined,
  CreditCardOutlined,
  BankOutlined,
} from '@ant-design/icons';
import React, { useContext, useState, useEffect } from 'react';
import { Button, Empty, Radio, Spin, message, Table, Tooltip, Image } from 'antd';
import axios from 'axios';
import { DateFilterDropdown } from './DateFilterDropdown.js';
import { DescriptionSearchDropdown } from './DescriptionSearchDropdown.js';
import { authContext } from '../ProvideAuth.js';
import { get_moments_from_month_name } from '../utils.js';
import { FormattedUSD } from './FormattedUSD.js';
import { AccountingAccountSelect } from './AccountingAccountSelect.js';
import { convertToIntDateFormat } from '../utils.js';
import { PdfViewer } from './PdfViewer.js';
import './styles.css';

const BankTable = (props) => {
  const [data, setData] = useState([]);
  const [flagging, setFlagging] = useState([]); // holds the key to the row that is flagging
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const auth = useContext(authContext);
  const [accountFilterOptions, setAccountFilterOptions] =
    useState([]);

  const fetchAccountOptions = (selectedSot) => {
    props.setLoading(true);
    axios({
      method: 'post',
      url: props.API_domain + 'getAccountsOptions',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        selectedSot: selectedSot,
      },
    })
      .then((response) => {
        props.setAccountOptions(response.data);
        props.setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching accounting accounts:', error);
      });
  };

  useEffect(() => {
    props.setLoading(true);
    setData([]);

    const fetchData = async () => {
      const sot_id = props.selectedSot
        ? props.selectedSot.sources_of_truth_id
        : null;
      // const month = 10;
      if (!sot_id) {
        props.setLoading(false);
        return;
      }

      try {
        const response = await axios({
          method: 'post',
          url: props.API_domain + 'getSotTransactions',
          data: { sot_id },
          auth: { username: auth.email, password: auth.token },
        });

        // Transform data to match table columns
        const transformedData = response.data.map((item, index) => ({
          ...item,
          key: item.id,
          Descripcion: item.description,
          Fecha: item.txn_date,
          Date: new Date(item.txn_date),
          fechaFormatted: convertToIntDateFormat(item.txn_date),
          Credito: item.amount > 0 ? Math.abs(item.amount) : null,
          Debito: item.amount < 0 ? Math.abs(item.amount) : null,
          balance: item.extra_info.balance,
          sotTxnId: item.id,
          flagged: item.flagged,
          comments: item.comments,
          committed: item.committed,
          // flagged: Math.random() >= 0.5,
        }));

        transformedData.sort((a, b) => {
          // First compare by Date
          const dateDifference = b.Date - a.Date;
          if (dateDifference !== 0) {
            return dateDifference;
          }

          // If the Dates are equal, compare by id
          return b.sotTxnId - a.sotTxnId; // Assuming larger ids should come first
        });
        setData(transformedData);
        props.setCurrentData(transformedData);
      } catch (error) {
        console.error('Error fetching SOT data', error);
      }

      props.setLoading(false);
      if (props.selectedSot) {
        fetchAccountOptions(props.selectedSot);
      }
    };

    fetchData();
  }, [props.selectedSot, props.uploadChanged, props.submittedToggle]);

  useEffect(() => {
    getAccountFilterOptions();
  }, [props.accountOptions, data]);

  useEffect(() => {
    setSelectedRowKeys(props.setSelectedSotTxnsInMassEdit);
  }, [props.setSelectedSotTxnsInMassEdit]);

  const getAccountFilterOptions = () => {
    const uniqueAccounts = new Map();
    uniqueAccounts.set(null, { text: 'Sin cuenta asignada', value: 'none' }); // Special option for unassigned

    const accountOptionsMap = new Map();
    props.accountOptions.forEach((option) => {
      accountOptionsMap.set(option.id, option);
    });

    data.forEach((item) => {
      if (
        item.account_id &&
        !uniqueAccounts.has(item.account_id)
      ) {
        const accountOption = accountOptionsMap.get(item.account_id);

        if (accountOption) {
          uniqueAccounts.set(item.account_id, {
            text: accountOption.name,
            value: item.account_id.toString(),
          });
        }
      }
    });

    // Convert the map to an array and sort it alphabetically by the 'text' property
    const sortedAccounts = Array.from(uniqueAccounts.values())
      .filter((account) => account.value !== 'none') // Exclude the special 'none' option from sorting
      .sort((a, b) => a.text.localeCompare(b.text));

    // Add the special 'none' option at the beginning of the sorted array
    const noneOption = uniqueAccounts.get(null);
    if (noneOption) {
      sortedAccounts.unshift(noneOption);
    }

    setAccountFilterOptions(sortedAccounts);
  };

  const onAccountChange = (value, record) => {
    const updatedData = data.map((item) => {
      if (item.key === record.key) {
        return { ...item, account_id: value };
      }
      return item;
    });
    setData(updatedData);
  };

  const updateSotTxnFlagged = (record) => {
    setFlagging([...flagging, record.key]);
    axios({
      method: 'post',
      url: props.API_domain + 'flagSotTxn',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: { sot_txn_id: record.sotTxnId },
    })
      .then((response) => {
        setFlagging(flagging.filter((key) => key !== record.key));
        // Update local state for the flagged status
        const updatedData = data.map((item) => {
          if (item.key === record.key) {
            return { ...item, flagged: !item.flagged };
          }
          return item;
        });
        setData(updatedData);
      })
      .catch((error) => {
        console.error('Error flagging transaction: ', error);
      });
  };

  const onSelectedRowsChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    props.setSelectedSotTxnsInMassEdit(selectedRows);
  };

  // Define rowSelection only if the user is an admin
  const rowSelectionConfig = auth.adminEmail
    ? {
        selectedRowKeys,
        onChange: onSelectedRowsChange,
      }
    : null; // or undefined

  const columns = [
    {
      title: 'Descripción',
      dataIndex: 'Descripcion',
      key: 'Descripcion',
      width: 420,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <DescriptionSearchDropdown
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        record.Descripcion.toLowerCase().includes(value.toLowerCase()),
      render: (descripcion) => (
        <Tooltip placement='topLeft' title={descripcion}>
          {descripcion}
        </Tooltip>
      ),
    },
    {
      title: 'Credito',
      dataIndex: 'Credito',
      key: 'Credito',
      width: 100,
      showSorterTooltip: false,
      align: 'right',
      sorter: (a, b) => parseFloat(a.Credito || 0) - parseFloat(b.Credito || 0),
      render: (credito) =>
        credito && <FormattedUSD total={parseFloat(credito)} />,
    },
    {
      title: 'Debito',
      dataIndex: 'Debito',
      key: 'Debito',
      width: 100,
      showSorterTooltip: false,
      align: 'right',
      sorter: (a, b) => parseFloat(a.Debito || 0) - parseFloat(b.Debito || 0),
      render: (debito) => debito && <FormattedUSD total={parseFloat(debito)} />,
    },
    {
      title: 'Saldo',
      dataIndex: 'balance',
      key: 'balance',
      width: 100,
      showSorterTooltip: false,
      align: 'right',
      sorter: (a, b) => parseFloat(a.balance || 0) - parseFloat(b.balance || 0),
      render: (balance) => <FormattedUSD total={parseFloat(balance)} />,
    },
    {
      title: 'Fecha',
      dataIndex: 'fechaFormatted',
      key: 'fechaFormatted',
      width: 120,
      sorter: (a, b) => new Date(a.txn_date) - new Date(b.txn_date),
      showSorterTooltip: false,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <DateFilterDropdown
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        const current_date = new Date(record.Fecha);
        current_date.setHours(current_date.getHours() + 5);
        if (typeof value == 'string') {
          let filter_dates = get_moments_from_month_name(value);
          return (
            current_date >= filter_dates[0] && current_date <= filter_dates[1]
          );
        }
        return current_date >= value[0] && current_date <= value[1];
      },
    },
    ...(auth.adminEmail
      ? [
          {
            title: 'Cuenta Contable 🦸‍♂️',
            dataIndex: 'account_id',
            key: 'account_id',
            width: 160,
            filters: accountFilterOptions,
            onFilter: (value, record) => {
              return value === 'none'
                ? !record.account_id
                : record.account_id?.toString() === value;
            },
            render: (_, record) => {
              return (
                <>
                  <AccountingAccountSelect
                    API_domain={props.API_domain}
                    accountOptions={props.accountOptions}
                    record={record}
                    onChange={onAccountChange}
                    allowClear={true}
                  />
                </>
              );
            },
          },
          {
            title: '🦸‍♂️',
            dataIndex: 'flagged',
            key: 'flagged',
            width: 80,
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div style={{ padding: 8 }}>
                <Radio.Group
                  onChange={(e) => {
                    setSelectedKeys(
                      e.target.value !== undefined ? [e.target.value] : []
                    );
                    confirm();
                  }}
                  value={selectedKeys[0]}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Radio value={true}>
                    <FlagFilled />
                  </Radio>
                  <Radio value={false}>
                    <FlagOutlined />
                  </Radio>
                </Radio.Group>
                <Button
                  onClick={() => {
                    clearFilters();
                    confirm();
                  }}
                  size='small'
                  style={{ width: 90, marginTop: 8 }}
                >
                  Reset
                </Button>
              </div>
            ),
            filterIcon: (filtered) => (
              <FlagOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
              />
            ),
            onFilter: (value, record) => {
              if (value === true) {
                return record.flagged === true;
              } else if (value === false) {
                return record.flagged === false;
              }
              return false;
            },
            render: (_, record) =>
              record.account_id && (
                <>
                  {flagging.includes(record.key) ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          spin
                          style={{ color: 'black', marginLeft: 12 }}
                        />
                      }
                    />
                  ) : (
                    <Tooltip
                      placement='left'
                      title={
                        record.flagged ? 'Quitar duda' : 'Marcar como duda'
                      }
                    >
                      <Button
                        className='icon-button'
                        onClick={() => updateSotTxnFlagged(record)}
                        style={{ marginLeft: 5 }}
                      >
                        <>
                          {record.flagged ? <FlagFilled /> : <FlagOutlined />}{' '}
                          {record.comments && (
                            <Tooltip title={record.comments}>
                              <CommentOutlined />
                            </Tooltip>
                          )}
                        </>
                      </Button>
                    </Tooltip>
                  )}
                </>
              ),
          },
          {
            title: '✅',
            dataIndex: 'committed',
            key: 'committed',
            width: 80,
            filters: [
              { text: 'Committed', value: true },
              { text: 'Not Committed', value: false },
            ],
            filterMultiple: false,
            onFilter: (value, record) => record.committed === value,
            render: (committed) => (
              <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>{committed ? '✅' : ''}</span>
            ),
          }
        ]
      : []),
    {
      title: '🔗',
      key: 'related',
      width: 40,
      filters: [
        { text: 'Related', value: true },
        { text: 'Unrelated', value: false },
      ],
      onFilter: (value, record) => record.related === value,
      render: (record) => (
        <div>
          {record.cufe_url && !console.log(record.cufe_url) && (
            <PdfViewer
              pdfUrl={record.cufe_url}
            />
          )}
          {!record.cufe_url && record.cashflow_url && !console.log(record.cashflow_url) && (
            <Image width={24} src={record.cashflow_url} />
          )}
          {!record.cufe_url && !record.cashflow_url && record.type_of_mm && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              {record.type_of_mm === 'bank' && <BankOutlined />}
              {record.type_of_mm === 'card' && <CreditCardOutlined />}
            </div>
          )}
        </div>
      ),
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    props.setCurrentData(extra['currentDataSource']);
  };

  return (
    <Table
      rowSelection={rowSelectionConfig} // Conditionally pass rowSelection
      dataSource={data}
      columns={columns}
      pagination={{ defaultPageSize: 50 }}
      style={{ width: '100%' }}
      loading={props.loading}
      onChange={onChange}
      locale={{
        emptyText: (
          <Empty
            style={{ paddingTop: '20vh', paddingBottom: '20vh' }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <>
                <div>Selecciona un banco para ver sus transacciones</div>
              </>
            }
          />
        ),
      }}
    />
  );
};

export { BankTable };
