import React, { useContext, useEffect, useState } from 'react';
import { Affix, Col, Layout, message, Row, Button } from 'antd';
import styled from 'styled-components/macro';
import axios from 'axios';
import { BankView } from './BankView.js';
import { ExpensesView } from './ExpensesView.js';
import { InvuTable } from './InvuTable.js';
import { FETable } from './FETable.js';
import { PedidosYaTable } from './PedidosYaTable.js';
import { authContext } from '../ProvideAuth.js';

const { Content } = Layout;

function PaperMovementsView(props) {
  const [activeComponent, setActiveComponent] = useState('expenses');
  const [enabledSites, setEnabledSites] = useState([]);
  const auth = useContext(authContext);
  const [clientId, setClientId] = useState(null);
  const [clientAccounts, setClientAccounts] = useState([]);

  useEffect(() => {
    const fetchClientInfo = async () => {
      try {
        // First, get the client info
        const clientInfoResponse = await axios({
          method: 'post',
          url: `${props.API_domain}/getUserAccountingClientCompanyInfo`,
          auth: {
            username: auth.email,
            password: auth.token,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (clientInfoResponse.status === 200) {
          const data = clientInfoResponse.data;
          const accountingClientId = data.accounting_client_id;
          setClientId(accountingClientId);

          // Now fetch the accounts
          const accountsResponse = await axios({
            method: 'post',
            url: `${props.API_domain}/getClientAccounts`,
            auth: {
              username: auth.email,
              password: auth.token,
            },
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              client_id: accountingClientId,
            },
          });

          if (accountsResponse.status === 200) {
            const accountsData = accountsResponse.data;
            setClientAccounts(accountsData.accounts);
          } else {
            console.error('Failed to fetch client accounts');
          }
        } else {
          console.error('Failed to fetch client info');
        }
      } catch (error) {
        console.error('Error fetching client info or accounts:', error);
      }
    };

    fetchClientInfo();
  }, [auth.email, auth.password, props.API_domain]);

  useEffect(() => {
    const getUserAccountingClientCompanyInfo = async () => {
      try {
        const response = await axios.post(
          props.API_domain + 'getEnabledClientConfigsForAccountingClient',
          {},
          {
            auth: {
              username: auth.email,
              password: auth.token,
            },
          }
        );
        setEnabledSites(response.data);
      } catch (error) {
        message.error('Error consiguiendo las fuentes');
        console.error('Error fetching accounting client:', error);
      }
    };

    getUserAccountingClientCompanyInfo();
  }, [auth, props.API_domain]);

  const renderComponent = () => {
    // Common props to pass to child components
    const commonProps = {
      API_domain: props.API_domain,
      clientId: clientId,
      clientAccounts: clientAccounts,
    };

    switch (activeComponent) {
      case 'expenses':
        return (
          <ExpensesView
            {...commonProps}
            team={props.team}
            onTeamSelect={props.onTeamSelect}
            mobile={props.mobile}
          />
        );
      case 'invu':
        return (
          <InvuTable
            {...commonProps}
            team={props.team}
            mobile={props.mobile}
          />
        );
      case 'feRecibidas':
        return (
          <FETable
            {...commonProps}
            team={props.team}
            mobile={props.mobile}
            type='fe_recibidas'
          />
        );
      case 'feEmitidas':
        return (
          <FETable
            {...commonProps}
            team={props.team}
            mobile={props.mobile}
            type='fe_emitidas'
          />
        );
      case 'pedidosya':
        return (
          <PedidosYaTable
            API_domain={props.API_domain}
            team={props.team}
            mobile={props.mobile}
            type='fe_emitidas'
          />
        );
      default:
        return <BankView {...commonProps} />;
    }
  };

  return (
    <Content
      style={{
        margin: '0 0 0',
        overflow: 'initial',
        borderRight: 'solid rgb(235,235,235) 1px',
      }}
    >
      <Affix>
        <AffixDiv>
          <Row>
            <Col span={12} style={{ textAlign: 'left' }}>
              <NavButton
                type='link'
                onClick={() => setActiveComponent('expenses')}
                isActive={activeComponent === 'expenses'}
              >
                Bot de WhatsApp
              </NavButton>
              <NavButton
                type='link'
                onClick={() => setActiveComponent('feRecibidas')}
                isActive={activeComponent === 'feRecibidas'}
              >
                FE Recibidas
              </NavButton>
              <NavButton
                type='link'
                onClick={() => setActiveComponent('feEmitidas')}
                isActive={activeComponent === 'feEmitidas'}
              >
                FE Emitidas
              </NavButton>
              {enabledSites.includes('invu') && (
                <NavButton
                  type='link'
                  onClick={() => setActiveComponent('invu')}
                  isActive={activeComponent === 'invu'}
                  disabled={auth.adminEmail ? false : true}
                >
                  Invu
                </NavButton>
              )}
              {enabledSites.includes('pedidosya') && (
                <NavButton
                  type='link'
                  onClick={() => setActiveComponent('pedidosya')}
                  isActive={activeComponent === 'pedidosya'}
                  disabled={auth.adminEmail ? false : true}
                >
                  Pedidos Ya
                </NavButton>
              )}
              {/* Add more buttons for additional components */}
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
            </Col>
          </Row>
        </AffixDiv>
      </Affix>

      <div>{renderComponent()}</div>
    </Content>
  );
}

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
`;

const NavButton = styled(Button)`
  color: ${({ isActive }) => (isActive ? 'rgb(116, 116, 205)' : '#000')};
  font-weight: ${({ isActive }) => (isActive ? '600' : 'normal')};
  font-size: 16px;
  background: none;
  border: none;

  &:hover {
    color: ${({ isActive }) => (isActive ? 'rgb(116, 116, 205)' : '#000')};
    background: none;
    border: none;
    box-shadow: none;
  }

  &:focus {
    color: ${({ isActive }) => (isActive ? 'rgb(116, 116, 205)' : '#000')};
    background: none;
    border: none;
    box-shadow: none;
    outline: none;
  }

  &:active {
    color: ${({ isActive }) => (isActive ? 'rgb(116, 116, 205)' : '#000')};
    background: none;
    border: none;
    box-shadow: none;
  }
`;

export { PaperMovementsView };
